<template>
  <responsive-layout>
    <router-view />
  </responsive-layout>
</template>

<script>
import ResponsiveLayout from "./components/ResponsiveLayout.vue";

export default {
  name: "App",
  components: {
    ResponsiveLayout
  }
};
</script>

<style>
/* 全局字体设置 */
html, body {
  font-family: "PingFang SC", -apple-system, "SF Pro SC", "SF Pro Text", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

