<template>
  <div class="card-1">
    <div class="overlap-group2-1">
      <img class="frame-347" :src="frame347" alt="Frame 347" />
      <div class="overlap-group-21">
        <img class="design" :src="design" alt="Design" />
        <div class="rectangle-306"></div>
        <img
          class="vector-4"
          src="/assets/images/icons/vector-37.png"
          alt="Vector"
        />
        <img class="frame-352" :src="frame352" alt="Frame 352" /><img class="vector-5" :src="vector2" alt="Vector" />
        <div class="frame-350"><img class="frame-357" :src="frame357" alt="Frame 357" /></div>
      </div>
      <div class="rectangle-308"></div>
      <div class="overlap-group1-4">
        <div class="rectangle-310"></div>
        <div class="rectangle-309"></div>
        <img class="vector-6" :src="vector3" alt="Vector" /><img class="vector-7" :src="vector4" alt="Vector" />
        <div class="autogameinc-00001">autogame.inc 00001</div>
      </div>
      <div class="frame-354">
        <div class="frame-353">
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
          <div class="rectangle-3"></div>
        </div>
      </div>
      <div class="serialnumber001">serialnumber.001</div>
      <img
        class="rectangle-329"
        src="/assets/images/illustrations/rectangle-329-2.png"
        alt="Rectangle 329"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card3",
  props: ["frame347", "design", "frame352", "vector2", "frame357", "vector3", "vector4"],
};
</script>

<style>
.card-1 {
  align-items: flex-start;
  background-image: url(/assets/images/illustrations/card.png);
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: -8px 15px 30px #00000096;
  display: flex;
  height: 543.52px;
  margin-left: 36.1px;
  margin-top: 0;
  overflow: hidden;
  width: 349.47px;
}

.overlap-group2-1 {
  height: 571px;
  margin-left: -199px;
  margin-top: -27.88px;
  position: relative;
  width: 584px;
}

.frame-347 {
  height: 289px;
  left: 287px;
  position: absolute;
  top: 283px;
  width: 261px;
}

.overlap-group-21 {
  height: 571px;
  left: 0;
  position: absolute;
  top: 0;
  width: 584px;
}

.design {
  height: 254px;
  left: 199px;
  position: absolute;
  top: 318px;
  width: 273px;
}

.rectangle-306 {
  background-color: #1e1e1e;
  height: 307px;
  left: 162px;
  position: absolute;
  top: 27px;
  width: 422px;
}

.vector-4 {
  height: 297px;
  left: 367px;
  position: absolute;
  top: 28px;
  width: 181px;
}

.frame-352 {
  height: 469px;
  left: 199px;
  position: absolute;
  top: 28px;
  width: 349px;
}

.vector-5 {
  height: 328px;
  left: 199px;
  position: absolute;
  top: 28px;
  width: 349px;
}

.frame-350 {
  display: flex;
  height: 356px;
  left: 0;
  position: absolute;
  top: 0;
  width: 560px;
}

.frame-357 {
  height: 328.47px;
  margin-left: 198.5px;
  margin-top: 27.9px;
  width: 349.47px;
}

.rectangle-308 {
  background-color: var(--black);
  border-radius: 40.43px;
  height: 22px;
  left: 338px;
  position: absolute;
  top: 40px;
  width: 71px;
}

.overlap-group1-4 {
  height: 60px;
  left: 280px;
  position: absolute;
  top: 511px;
  width: 271px;
}

.rectangle-310 {
  background: linear-gradient(180deg, rgb(55.53, 33.76, 64.13) 0%, rgb(42, 116, 232) 55.74%, rgb(246, 246, 236) 100%);
  box-shadow: inset 0px 0.5px 0.5px #ffffff, 0px 0px 2px #00000045;
  height: 59px;
  left: 0;
  position: absolute;
  top: 0;
  width: 271px;
}

.rectangle-309 {
  background-color: var(--white);
  height: 52px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 232px;
}

.vector-6 {
  height: 43px;
  left: 117px;
  position: absolute;
  top: 9px;
  width: 112px;
}

.vector-7 {
  height: 43px;
  left: 4px;
  position: absolute;
  top: 9px;
  width: 112px;
}

.autogameinc-00001 {
  color: var(--black);
  font-family: var(--font-family-consolas-bold);
  font-size: 4px;
  font-weight: 700;
  left: 3px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 52px;
}

.frame-354 {
  box-shadow: -1px 2px 5px #00000033, -6px 6px 8px #0000002b, -13px 14px 11px #0000001a, -22px 25px 13px #00000008,
    -35px 39px 15px transparent;
  display: flex;
  height: 78px;
  left: 457px;
  overflow: hidden;
  position: absolute;
  top: 380px;
  width: 78px;
}

.frame-353 {
  align-items: center;
  display: inline-flex;
  gap: 5.37px;
  height: 140.95px;
  margin-left: -20.7px;
  margin-top: -31.6px;
  position: relative;
  transform: rotate(45deg);
  width: 113.79px;
}

.rectangle-3 {
  background-color: var(--white);
  height: 140.95px;
  position: relative;
  width: 7.87px;
}

.serialnumber001 {
  color: var(--white);
  font-family: var(--font-family-consolas-bold);
  font-size: 16.7px;
  font-weight: 700;
  left: 214px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 443px;
  white-space: nowrap;
}

.rectangle-329 {
  height: 544px;
  left: 199px;
  position: absolute;
  top: 28px;
  width: 349px;
}
</style>

