<template>
  <div class="frame-371">
    <div class="frame-373">
      <div class="text-1 pingfangsc-normal-gallery-16px">{{ text22 }}</div>
    </div>
    <div class="index dinpro-bold-sonic-silver-16px">{{ index }}</div>
  </div>
</template>

<script>
export default {
  name: "Frame371",
  props: ["text22", "index"],
};
</script>

<style>
.frame-371,
.frame-371-1,
.frame_371,
.frame-371-2,
.frame-371-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
}

.frame-373,
.frame-373-1,
.frame_373,
.frame-373-2,
.frame-373-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.text-1,
.text-2,
.text-36,
.x2,
.text-3,
.text-7 {
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index,
.index-1,
.index-2,
.index-3,
.index-4 {
  letter-spacing: -0.48px;
  line-height: normal;
  position: relative;
  width: 100px;
}
</style>
