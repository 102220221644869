<template>
  <div class="frame_485">
    <div class="chat_-haruhi-1 pingfangsc-normal-black-16px">{{ chat_Haruhi }}</div>
  </div>
</template>

<script>
export default {
  name: "Frame485",
  props: ["chat_Haruhi"],
};
</script>

<style>
.frame_485,
.frame-485 {
  align-items: center;
  align-self: stretch;
  background-color: var(--blue);
  display: flex;
  gap: 10px;
  height: 36px;
  padding: 2px 11px;
  position: relative;
  width: 100%;
}

.chat_-haruhi-1,
.text-41 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
</style>
