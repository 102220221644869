<template>
  <div class="main-button">
    <div class="frame-457">
      <div class="frame-454-2">
        <img
          class="union-11"
          src="/assets/images/icons/union-27.png"
          alt="观看视频播放按钮"
        />
      </div>
    </div>
    <div class="frame-452"><div class="pv">观看「麦琪的花园」PV</div></div>
        <img
      class="frame-455"
      src="/assets/images/icons/frame-455.png"
      alt="按钮装饰边框"
    />
  </div>
</template>

<script>
export default {
  name: "MainButton",
};
</script>

<style>
.main-button {
  align-items: center;
  box-shadow: -8px 8px 1px #00000029;
  display: inline-flex;
  left: 0;
  position: absolute;
  top: 49px;
}

.frame-457 {
  border: 2px solid;
  border-color: var(--gallery);
  display: flex;
  height: 63px;
  justify-content: center;
  position: relative;
  width: 45px;
}

.frame-454-2 {
  align-items: flex-start;
  display: flex;
  margin-left: 5px;
  margin-top: 20px;
  min-width: 22px;
}

.union-11 {
  height: 25px;
  width: 21px;
}

.frame-452 {
  align-items: center;
  background-color: var(--gallery);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 63px;
  justify-content: center;
  padding: 18px 0px 18px 20px;
  position: relative;
}

.pv {
  color: var(--black);
  font-family: "PingFang SC Medium", "PingFang SC", -apple-system, "SF Pro SC", "SF Pro Text", "Helvetica Neue", sans-serif !important;
  font-size: var(--font-size-l);
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame-455 {
  height: 63px;
  position: relative;
  width: 26px;
}
</style>

