<template>
  <div :class="[`frame-376`, className || ``]">
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
    <div class="rectangle-3-1"></div>
  </div>
</template>

<script>
export default {
  name: "Frame376",
  props: ["className"],
};
</script>

<style>
.frame-376 {
  align-items: center;
  display: inline-flex;
  gap: 0.95px;
  left: 1px;
  position: absolute;
  top: 461px;
}

.rectangle-3-1 {
  background-color: var(--heavy-metal);
  border-radius: 34px;
  height: 5.73px;
  position: relative;
  width: 1.66px;
}

.frame-376.frame-400 {
  top: 293px;
}

.frame-376.frame-424 {
  top: 125px;
}

.frame-376.frame-382 {
  top: 419px;
}

.frame-376.frame-401 {
  top: 251px;
}

.frame-376.frame-425 {
  top: 83px;
}

.frame-376.frame-388 {
  top: 377px;
}

.frame-376.frame-402 {
  top: 209px;
}

.frame-376.frame-426 {
  top: 41px;
}

.frame-376.frame-394 {
  top: 335px;
}

.frame-376.frame-403 {
  top: 167px;
}

.frame-376.frame-427 {
  top: -1px;
}

.frame-376.frame-378 {
  top: 447px;
}

.frame-376.frame-404 {
  top: 279px;
}

.frame-376.frame-428 {
  top: 111px;
}

.frame-376.frame-383 {
  top: 405px;
}

.frame-376.frame-405 {
  top: 237px;
}

.frame-376.frame-429 {
  top: 69px;
}

.frame-376.frame-389 {
  top: 363px;
}

.frame-376.frame-406 {
  top: 195px;
}

.frame-376.frame-430 {
  top: 27px;
}

.frame-376.frame-395 {
  top: 321px;
}

.frame-376.frame-407 {
  top: 153px;
}

.frame-376.frame-431 {
  top: -15px;
}

.frame-376.frame-380 {
  top: 433px;
}

.frame-376.frame-408 {
  top: 265px;
}

.frame-376.frame-432 {
  top: 97px;
}

.frame-376.frame-384 {
  top: 391px;
}

.frame-376.frame-409 {
  top: 223px;
}

.frame-376.frame-433 {
  top: 55px;
}

.frame-376.frame-390 {
  top: 349px;
}

.frame-376.frame-410 {
  top: 181px;
}

.frame-376.frame-434 {
  top: 13px;
}

.frame-376.frame-396 {
  top: 307px;
}

.frame-376.frame-411 {
  top: 139px;
}

.frame-376.frame-435 {
  top: -29px;
}
</style>
