<template>
  <div class="container-center-horizontal">
    <div class="x4-magiscapes screen">
      <div class="overlap-group5">
        <div class="bg-1">
          <img class="image-2400x2400-2-1-1" :src="image2400X240021" alt="Image 2400x2400 (2)  1" /><img
            class="frame-475-1"
            :src="frame475"
            alt="Frame 475"
          />
          <div class="overlap-group-9">
            <img class="rectangle-365-1" :src="rectangle365" alt="Rectangle 365" />
            <div class="ellipse-98-1"></div>
            <img
              class="overlap-group-item-1"
              src="/assets/images/icons/vector.png"
              alt="Vector"
            />
            <img class="overlap-group-item-1" :src="image2400X240023" alt="Image 2400x2400 (2)  3" />
          </div>
        </div>
        <bg :frame478="bGProps.frame478" :frame526="bGProps.frame526" />
        <div class="frame-525">
          <div class="frame-517">
            <div class="frame-504">
              <div class="frame-container">
                <div class="frame-505">
                  <img class="magiscapes" :src="magiscapes" alt="麦琪的花园 - AI沙盒游戏" />
                  <div class="rectangle-383"></div>
                </div>
                <div class="frame-496">
                  <div class="frame-493">
                    <div class="frame-494">
                      <div class="flex-row">
                        <div class="text-24 valign-text-middle">{{ text1 }}</div>
                        <div class="frame-495" :style="{ 'background-image': 'url(' + frame495 + ')' }"></div>
                        <img
                          class="x1-1"
                          src="/assets/images/logo/---1-1.svg"
                          alt="1"
                        />
                        <img class="union-5" :src="union1" alt="Union" />
                      </div>
                    </div>
                    <h1 class="ai-6">{{ ai }}</h1>
                  </div>
                  <div class="text-25 pingfangsc-regular-normal-white-16px">{{ text2 }}</div>
                </div>
              </div>
            </div>
            <div class="frame-516">
              <div class="frame-5-2">
                <frame500 :text3="frame5001Props.text3" :union="frame5001Props.union" :text4="frame5001Props.text4" />
                <div class="frame-498">
                  <div class="overlap-group3">
                    <div class="frame-497"></div>
                    <div class="t_grass-container">
                      <img
                        class="t_-grasslanddepression_1-1"
                        :src="t_Grasslanddepression_11"
                        alt="T_Grasslanddepression_1 1"
                      /><img class="t_-grass_2-1" :src="t_Grass_21" alt="T_Grass_2 1" />
                    </div>
                    <div class="overlap-group1">
                      <img class="t_-snow_4" :src="t_Snow_4" alt="T_Snow_4" /><img
                        class="x1-1-1"
                        :src="x11"
                        alt="1 1"
                      />
                    </div>
                    <div class="overlap-group2">
                      <img class="t_-brown-desert_1" :src="t_BrownDesert_1" alt="T_Brown Desert_1" /><img
                        class="t_-cactus_2-1"
                        :src="t_Cactus_21"
                        alt="T_Cactus_2 1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame-5-2">
                <frame500 :text3="frame5002Props.text3" :union="frame5002Props.union" :text4="frame5002Props.text4" />
                <div class="frame-50">
                  <div class="overlap-group-10">
                    <div class="frame-497-1"></div>
                    <img class="mask-group" :src="maskGroup1" alt="Mask group" /><img
                      class="mask-group-1"
                      :src="maskGroup2"
                      alt="Mask group"
                    /><img class="x1-2" :src="x12" alt="1" />
                  </div>
                </div>
              </div>
              <div class="frame-5-2">
                <frame500 :text3="frame5003Props.text3" :union="frame5003Props.union" :text4="frame5003Props.text4" />
                <div class="frame-506">
                  <div class="overlap-group-11">
                    <div class="frame-497-2"></div>
                    <img class="x20240823-142701" :src="x20240823142701" alt="20240823-142701" /><img
                      class="x20240823-142706"
                      :src="x20240823142706"
                      alt="20240823-142706"
                    />
                  </div>
                </div>
              </div>
              <div class="frame-5-2">
                <frame500 :text3="frame5004Props.text3" :union="frame5004Props.union" :text4="frame5004Props.text4" />
                <div class="frame-50">
                  <div class="overlap-group-12"><img class="frame-512" :src="frame512" alt="Frame 512" /></div>
                </div>
              </div>
            </div>
          </div>
          <div class="frame-524">
            <x-button :steam="xButtonProps.steam" :className="xButtonProps.className" />
            <div class="frame-483">
              <div class="rectangle-381"></div>
              <a href="https://qm.qq.com/q/D0uKsBleww" target="_blank" class="qq-link">
                <div class="qq-group-container">
                  <font-awesome-icon :icon="['fab', 'qq']" class="qq-icon" />
                  <div class="qq-455876449 dinpro-bold-white-16px">{{ qq455876449 }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="frame-514"><img class="frame" :src="frame" alt="Frame" /></div>
        <content v-bind="contentProps" />
        <div class="frame-513">
          <div class="overlap-group4">
            <img class="frame-515" :src="frame515" alt="Frame 515" /><img
              class="t_-aurelia_-avatar-1"
              :src="t_Aurelia_Avatar1"
              alt="T_Aurelia_Avatar 1"
            />
            <div class="overlap-group-13" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
              <div class="text-33 valign-text-middle">{{ text10 }}</div>
            </div>
            <div class="frame-527">
              <div class="rectangle-275"></div>
              <div class="rectangle-276"></div>
              <div class="rectangle-277"></div>
            </div>
          </div>
        </div>
        <scroll-animation22 />
        <img class="union-6" :src="union2" alt="Union" />
      </div>
    </div>
  </div>
</template>

<script>
import Bg from "./Bg";
import Frame500 from "./Frame500";
import XButton from "./XButton";
import Content from "./Content";
import ScrollAnimation22 from "./ScrollAnimation22";
import Frame365 from "./Frame365";
import Frame3652 from "./Frame3652";
import Frame3653 from "./Frame3653";
import Lang from "./Lang";
export default {
  name: "X4Magiscapes",
  components: {
    Bg,
    Frame500,
    XButton,
    Content,
    ScrollAnimation22,
    Frame365,
    Frame3652,
    Frame3653,
    Lang,
  },
  props: [
    "image2400X240021",
    "frame475",
    "rectangle365",
    "image2400X240023",
    "magiscapes",
    "text1",
    "frame495",
    "union1",
    "ai",
    "text2",
    "t_Grasslanddepression_11",
    "t_Grass_21",
    "t_Snow_4",
    "x11",
    "t_BrownDesert_1",
    "t_Cactus_21",
    "maskGroup1",
    "maskGroup2",
    "x12",
    "x20240823142701",
    "x20240823142706",
    "frame512",
    "qq455876449",
    "frame",
    "frame515",
    "t_Aurelia_Avatar1",
    "overlapGroup",
    "text10",
    "union2",
    "bGProps",
    "frame5001Props",
    "frame5002Props",
    "frame5003Props",
    "frame5004Props",
    "xButtonProps",
    "contentProps",
    "frame3651Props",
    "frame3652Props",
    "frame3652Props2",
    "frame3653Props",
    "langProps",
  ],
};
</script>

<style>
.x4-magiscapes {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 1080px;
  overflow: hidden;
  width: 1920px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

.overlap-group5 {
  height: 1920px;
  margin-left: -2px;
  margin-top: -420px;
  position: relative;
  width: 1925px;
}

.bg-1 {
  align-items: flex-start;
  background-color: var(--eerie-black);
  display: flex;
  height: 1920px;
  left: 2px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1920px;
}

.image-2400x2400-2-1-1 {
  height: 1920px;
  margin-left: -16075px;
  margin-top: -3347px;
  object-fit: cover;
  width: 1920px;
}

.frame-475-1 {
  height: 605px;
  margin-left: 2px;
  margin-top: -4306px;
  width: 2335px;
}

.overlap-group-9 {
  height: 3767px;
  margin-left: 11818px;
  margin-top: -1877.37px;
  position: relative;
  width: 1920px;
}

.rectangle-365-1 {
  height: 1044px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2297px;
  width: 1920px;
}

.ellipse-98-1 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0.15) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 763.82px/1883.62px;
  height: 3767px;
  left: 196px;
  position: absolute;
  top: 0;
  width: 1528px;
}

.overlap-group-item-1 {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 2297px;
  width: 1920px;
}

.frame-525 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 161px;
  position: absolute;
  top: 558px;
}

.frame-517 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.frame-504 {
  align-self: stretch;
  height: 280px;
  position: relative;
  width: 100%;
}

.frame-container {
  height: 280px;
  position: relative;
  width: 658px;
}

.frame-505 {
  align-items: flex-start;
  display: inline-flex;
  gap: 4px;
  left: 0;
  position: absolute;
  top: 0;
}

.magiscapes {
  height: 31.86px;
  position: relative;
  width: 293.66px;
}

.rectangle-383 {
  background-color: var(--sonic-silver);
  height: 16px;
  position: relative;
  width: 16px;
}

.frame-496 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 20px;
  width: 658px;
}

.frame-493 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  position: relative;
  width: 100%;
}

.frame-494 {
  align-self: stretch;
  background-color: #e7e7e7;
  height: 58px;
  position: relative;
  width: 100%;
}

.flex-row {
  align-items: center;
  display: flex;
  left: 8px;
  min-width: 642px;
  position: relative;
}

.text-24 {
  color: var(--black);
  font-family: var(--font-family-pingfang_sc-semibold);
  font-size: 36px;
  font-weight: 400;
  height: 50px;
  letter-spacing: -0.72px;
  line-height: 50px;
  margin-bottom: 1.4px;
  min-width: 178px;
  white-space: nowrap;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
}

.frame-495 {
  background-size: 100% 100%;
  height: 5px;
  margin-left: 139px;
  margin-top: 1.6px;
  width: 129px;
}

.x1-1 {
  height: 13px;
  margin-left: 17px;
  margin-top: 1.12px;
  width: 125px;
}

.union-5 {
  height: 58px;
  margin-left: 6px;
  width: 48px;
}

.ai-6 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--font-family-pingfang_sc-semibold);
  font-size: 46px;
  font-weight: 400;
  height: 100px;
  letter-spacing: -0.92px;
  line-height: 50px;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
}

.text-25 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
}

.frame-516 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.frame-5-2 {
  align-self: stretch;
  display: flex;
  gap: 16px;
  height: 112px;
  position: relative;
  width: 100%;
}

.frame-498 {
  align-items: flex-start;
  display: flex;
  height: 112px;
  width: 287px;
}

.overlap-group3 {
  height: 143px;
  margin-top: -31px;
  position: relative;
  width: 287px;
}

.frame-497 {
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.21) 0%, rgba(115, 115, 115, 0) 100%);
  border: 1px solid;
  border-color: var(--gray);
  height: 86px;
  left: 0;
  position: absolute;
  top: 57px;
  width: 287px;
}

.t_grass-container {
  height: 86px;
  left: 13px;
  position: absolute;
  top: 44px;
  width: 62px;
}

.t_-grasslanddepression_1-1 {
  height: 62px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 24px;
  width: 62px;
}

.t_-grass_2-1,
.x1-1-1 {
  height: 62px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 62px;
}

.overlap-group1 {
  height: 70px;
  left: 110px;
  position: absolute;
  top: 60px;
  width: 64px;
}

.t_-snow_4 {
  height: 62px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 62px;
}

.overlap-group2 {
  height: 130px;
  left: 211px;
  position: absolute;
  top: 0;
  width: 62px;
}

.t_-brown-desert_1 {
  height: 62px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 68px;
  width: 62px;
}

.t_-cactus_2-1 {
  height: 124px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 62px;
}

.frame-50 {
  align-items: flex-end;
  display: flex;
  height: 112px;
  width: 287px;
}

.overlap-group-10 {
  height: 104px;
  position: relative;
  width: 287px;
}

.frame-497-1 {
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.21) 0%, rgba(115, 115, 115, 0) 100%);
  border: 1px solid;
  border-color: var(--gray);
  height: 86px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 287px;
}

.mask-group {
  height: 97px;
  left: 191px;
  position: absolute;
  top: 0;
  width: 85px;
}

.mask-group-1 {
  height: 89px;
  left: 97px;
  position: absolute;
  top: 8px;
  width: 80px;
}

.x1-2 {
  height: 81px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 60px;
}

.frame-506 {
  align-items: flex-start;
  display: flex;
  height: 112px;
  overflow: hidden;
  width: 287px;
  image-rendering: optimizeQuality;
}

.overlap-group-11 {
  height: 177px;
  margin-left: -1px;
  margin-top: -39px;
  position: relative;
  width: 288px;
  image-rendering: optimizeQuality;
}

.frame-497-2 {
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.21) 0%, rgba(115, 115, 115, 0) 100%);
  border: 1px solid;
  border-color: var(--gray);
  height: 86px;
  left: 1px;
  position: absolute;
  top: 65px;
  width: 287px;
}

.x20240823-142701 {
  height: 173px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 174px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.x20240823-142706 {
  height: 173px;
  left: 115px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 174px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.overlap-group-12 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.21) 0%, rgba(115, 115, 115, 0) 100%);
  border: 1px solid;
  border-color: var(--gray);
  display: flex;
  height: 86px;
  min-width: 287px;
  padding: 19px 0;
}

.frame-512 {
  height: 46px;
  margin-left: -1px;
  width: 287px;
}

.frame-524 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 272px;
}

.frame-483 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.rectangle-381 {
  background: linear-gradient(180deg, rgb(42, 116, 232) 38%, rgb(246, 246, 236) 100%);
  height: 19px;
  position: relative;
  width: 8px;
}

.qq-group-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.qq-icon {
  height: 16px;
  width: 16px;
  color: #ffffff;
  margin-top: 2px;
}

.qq-455876449 {
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
}

.qq-link {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s ease;
}

.qq-link:hover {
  opacity: 0.8;
}

/* 添加全局样式，确保所有GIF图片保持清晰 */
img[src$=".gif"] {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  transform: translateZ(0); /* 启用硬件加速 */
  backface-visibility: hidden; /* 提高渲染性能 */
  will-change: transform; /* 提示浏览器该元素会变化 */
}

/* 为指定的PNG图片添加邻近边缘渲染 */
img[src*="t-aurelia-avatar-1.png"],
img[src*="------1.png"],
img[src*="mask-group-1.png"],
img[src*="mask-group.png"],
img[src*="t-grasslanddepression-1-1.png"],
img[src*="t-grass-2-1.png"],
img[src*="t-snow-4.png"],
img[src*="--1-1.png"],
img[src*="t-cactus-2-1.png"],
img[src*="t-brown-desert-1.png"] {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

/* 添加响应式文本大小 */
@media screen and (max-width: 1600px) {
  .text-24 {
    font-size: 32px;
  }
  .ai-6 {
    font-size: 42px;
  }
  .text-25 {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .text-24 {
    font-size: 28px;
  }
  .ai-6 {
    font-size: 38px;
  }
  .text-25 {
    font-size: 12px;
  }
}

.frame-514 {
  align-items: center;
  display: flex;
  height: 869px;
  justify-content: center;
  left: 962px;
  position: absolute;
  top: 538px;
  width: 863px;
}

.frame {
  height: 777px;
  width: 863px;
}

.frame-513 {
  align-items: flex-start;
  display: flex;
  height: 869px;
  left: 962px;
  overflow: hidden;
  position: absolute;
  top: 538px;
  width: 863px;
}

.overlap-group4 {
  height: 1461px;
  margin-left: -293px;
  margin-top: -319.79px;
  position: relative;
  width: 1461px;
}

.frame-515 {
  height: 249px;
  left: 293px;
  position: absolute;
  top: 940px;
  width: 863px;
}

.t_-aurelia_-avatar-1 {
  height: 1461px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1461px;
}

.overlap-group-13 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 84px;
  left: 824px;
  min-width: 367px;
  padding: 20.9px 70.8px;
  position: absolute;
  top: 403px;
}

.text-33 {
  color: var(--white);
  font-family: var(--font-family-vonwaonbitmap_12px-regular);
  font-size: 19.2px;
  font-weight: 400;
  height: 40px;
  letter-spacing: 0.96px;
  line-height: 24px;
  width: 225px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
}

.frame-527 {
  align-items: center;
  display: inline-flex;
  gap: 7px;
  left: 1106px;
  position: absolute;
  top: 1177px;
}

.rectangle-275 {
  background-color: #bb4442;
  height: 12px;
  position: relative;
  transform: rotate(90deg);
  width: 12px;
}

.rectangle-276 {
  background-color: #f0d176;
  height: 12px;
  position: relative;
  transform: rotate(90deg);
  width: 12px;
}

.rectangle-277 {
  background-color: #333c46;
  height: 12px;
  position: relative;
  transform: rotate(90deg);
  width: 12px;
}

.union-6 {
  height: 350px;
  left: 66px;
  position: absolute;
  top: 1071px;
  width: 8px;
}
</style>

