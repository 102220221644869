<template>
  <div class="frame-50-1">
    <div class="frame-499">
      <div class="frame-501">
        <img
          class="frame-374-4"
          src="/assets/images/icons/frame-374-1.png"
          alt="Frame 374"
        />
        <div class="text-10 pingfangsc-normal-white-24px">{{ text3 }}</div>
      </div>
      <img class="union-7" :src="union" alt="Union" />
    </div>
    <div class="text-27 pingfangsc-regular-normal-white-16px">{{ text4 }}</div>
  </div>
</template>

<script>
export default {
  name: "Frame500",
  props: ["text3", "union", "text4"],
};
</script>

<style>
.frame-50-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 69px;
  margin-top: 26px;
  position: relative;
  width: 355px;
}

.frame-499 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.frame-501 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
  width: 100%;
}

.frame-374-4 {
  height: 20px;
  position: relative;
  width: 19px;
}

.text-10 {
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.union-7 {
  align-self: stretch;
  height: 3px;
  position: relative;
  width: 100%;
}

.text-27,
.etc,
.ai-agent,
.bert-vits2-fish-speech {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
}
</style>

