<template>
  <div class="container-center-horizontal">
    <div class="x2-strengths01 screen">
      <div class="overlap-group1-3">
        <bg3
          :frame475="bGProps.frame475"
          :image2400X240021="bGProps.image2400X240021"
          :rectangle365="bGProps.rectangle365"
          :image2400X240023="bGProps.image2400X240023"
        />
        <img class="frame-488" :src="frame488" alt="GameGPT技术示例" /><img
          class="frame-477"
          :src="frame477"
          alt="AI游戏框架示意图"
        /><img class="game-gpt" :src="gameGptImg" alt="GameGPT AI游戏开发技术" />
        <div class="frame-484">
          <div class="frame-478-2">
            <div class="frame-490">
              <div class="frame-489"><img class="subtract-3" :src="subtract" alt="技术标签图标" /></div>
              <div class="strengths valign-text-middle">
                <span class="dinpro-bold">STRENGTHS</span>
              </div>
            </div>
            <div class="frame-491">
              <div class="game-gpt-1 valign-text-middle montserrat-bold-white-44px" v-html="gameGpt"></div>
              <h1 class="ai-agent-1 pingfangsc-normal-white-50px">{{ aiagent }}</h1>
            </div>
          </div>
          <div class="frame-482">
            <p class="x1-multi-agent pingfangsc-regular-normal-white-16px">{{ x1MultiAgent }}</p>
            <div class="frame-483-1">
              <div class="rectangle-381-1"></div>
              <frame492 />
              <a href="https://arxiv.org/abs/2310.08067" target="_blank" class="arxiv-link">
                <div class="httpsarxivorgabs231008067 dinpro-bold-white-16px">{{ httpsArxivOrgAbs231008067 }}</div>
              </a>
            </div>
            <img class="vector-192" :src="vector192" alt="分隔线" />
          </div>
        </div>
        <div class="frame-486">
          <div class="frame-481"><img class="image-27" :src="arxivLogoOneColorWhite1" alt="ArXiv论文引用" /></div>
          <frame485 :chat_Haruhi="frame3651Props2.chat_Haruhi" />
        </div>
        <scroll-animation22 :className="langProps2.className" />
        <img class="union-9" :src="image27" alt="页面装饰元素" />
      </div>
    </div>
  </div>
</template>

<script>
import Bg3 from "./Bg3";
import Frame365 from "./Frame365";
import Frame3652 from "./Frame3652";
import Frame3653 from "./Frame3653";
import Lang from "./Lang";
import Frame492 from "./Frame492";
import Frame485 from "./Frame485";
import ScrollAnimation22 from "./ScrollAnimation22";
export default {
  name: "X2Strengths01",
  components: {
    Bg3,
    Frame365,
    Frame3652,
    Frame3653,
    Lang,
    Frame492,
    Frame485,
    ScrollAnimation22,
  },
  props: [
    "frame488",
    "frame477",
    "gameGpt",
    "subtract",
    "strengths",
    "aiagent",
    "x1MultiAgent",
    "httpsArxivOrgAbs231008067",
    "vector192",
    "arxivLogoOneColorWhite1",
    "image27",
    "bGProps",
    "frame3651Props",
    "frame3652Props",
    "frame3652Props2",
    "frame3653Props",
    "langProps",
    "frame3651Props2",
    "langProps2",
    "gameGptImg",
  ],
};
</script>

<style>
.x2-strengths01 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 1080px;
  overflow: hidden;
  width: 1920px;
}

.overlap-group1-3 {
  height: 1920px;
  margin-top: -420px;
  position: relative;
  width: 1920px;
}

.frame-488 {
  height: 102px;
  left: 960px;
  position: absolute;
  top: 620px;
  width: 800px;
}

.frame-477 {
  height: 409px;
  left: 0;
  position: absolute;
  top: 1091px;
  width: 1920px;
}

.game-gpt {
  height: 650px;
  left: 160px;
  position: absolute;
  top: 516px;
  width: 650px;
}

.nav-3 {
  -webkit-backdrop-filter: blur(30px) brightness(100%);
  backdrop-filter: blur(30px) brightness(100%);
  background-color: var(--licorice);
  display: flex;
  height: 88px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 420px;
  width: 1920px;
}

.frame-361-4 {
  align-self: center;
  background-image: url(/assets/images/logo/logo-1-17.svg);
  background-size: 100% 100%;
  height: 23.05px;
  margin-left: 80px;
  margin-top: 0;
  width: 230px;
}

.frame-362-2 {
  align-items: center;
  display: inline-flex;
  gap: 40px;
  height: 43px;
  justify-content: flex-end;
  margin-right: 80px;
  margin-top: 23px;
  position: relative;
  width: 863px;
}

.frame-484 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 152px;
  left: 160px;
  position: absolute;
  top: 695px;
  width: 650px;
}

.frame-478-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.frame-490 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame-489 {
  align-self: stretch;
  display: flex;
  height: 23px;
  position: relative;
  width: 100%;
}

.subtract-3 {
  height: 23.05px;
  margin-left: 0.5px;
  margin-top: -1.5px;
  width: 31.01px;
}

.strengths {
  letter-spacing: -2.4px;
  line-height: normal;
  opacity: 0.6;
  position: relative;
  width: fit-content;
  color: var(--alto);
  font-size: 24px;
}

.dinpro-regular {
  font-family: "DINPro", Helvetica, Arial, sans-serif !important;
  font-weight: normal !important;
}

.dinpro-bold {
  font-family: "DINPro-Bold", Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
}

.frame-491 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.game-gpt-1 {
  align-self: stretch;
  letter-spacing: -0.88px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.ai-agent-1 {
  height: 100px;
  letter-spacing: -1px;
  line-height: 60px;
  position: relative;
  width: 650px;
}

.frame-482 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 31px;
  position: relative;
  width: 100%;
}

.x1-multi-agent {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
}

.frame-483-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.rectangle-381-1 {
  background: linear-gradient(180deg, rgb(42, 116, 232) 38%, rgb(246, 246, 236) 100%);
  height: 19px;
  position: relative;
  width: 8px;
}

.httpsarxivorgabs231008067 {
  flex: 1;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
}

.vector-192 {
  height: 3px;
  margin-bottom: -1.5px;
  position: relative;
  width: 163px;
}

.frame-486 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 960px;
  position: absolute;
  top: 683px;
  width: 800px;
}

.frame-481 {
  align-self: stretch;
  background-color: #fffcf4;
  display: flex;
  height: 574px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.image-27 {
  height: 292px;
  margin-left: -8.1px;
  margin-top: 141px;
  object-fit: cover;
  width: 816.29px;
}

.union-9 {
  height: 350px;
  left: 64px;
  position: absolute;
  top: 1071px;
  width: 8px;
}

.arxiv-link {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s ease;
}

.arxiv-link:hover {
  opacity: 0.8;
}
</style>

