<template>
  <div class="scroll">
    <div class="overlap-group-14">
      <img
        class="vector-200"
        src="/assets/images/icons/vector-200-1.png"
        alt="Vector 200"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Scroll",
};
</script>

<style>
.scroll,
.scroll-1,
.scroll-2,
.scroll-3 {
  align-items: flex-end;
  display: flex;
  flex: 1;
  min-width: 39px;
  padding: 0.1px 0;
}

.overlap-group-14,
.overlap-group-15,
.overlap-group-16,
.overlap-group-17 {
  align-items: flex-end;
  background-image: url(/assets/images/icons/union-44.png);
  background-size: 100% 100%;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  min-width: 38px;
  padding: 11.2px 11.3px;
}

.vector-200,
.vector-200-1,
.vector-200-2,
.vector-200-3 {
  height: 8px;
  width: 8px;
}
</style>

