<template>
  <div class="frame-371-4">
    <div class="frame-373-4">
      <div class="text_label pingfangsc-normal-gallery-16px">麦琪的花园</div>
      <img class="frame-374" :src="frame374" alt="Frame 374" />
    </div>
    <div class="index-5 dinpro-bold-sonic-silver-16px">MAGISCAPES</div>
  </div>
</template>

<script>
export default {
  name: "Frame3712",
  props: ["frame374"],
};
</script>

<style>
.frame-371-4,
.frame-371-5,
.frame_371_3,
.frame-371-6,
.frame-371-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
}

.frame-373-4,
.frame-373-5,
.frame_373_3,
.frame-373-6,
.frame-373-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.text_label,
.text_label-1,
.x3,
.text_label-2,
.text_label-3 {
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame-374,
.frame-374-1,
.frame_374,
.frame-374-2,
.frame-374-3 {
  height: 25.63px;
  margin-bottom: -1.81px;
  margin-right: -3.31px;
  margin-top: -1.81px;
  position: relative;
  width: 25.63px;
}

.index-5,
.index-6,
.index_3,
.index-7,
.index-8 {
  letter-spacing: -0.48px;
  line-height: normal;
  position: relative;
  width: 100px;
}
</style>
