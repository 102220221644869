export const frame3711Data = {
    text22: "首页",
    index: "INDEX",
};

export const frame3651Data = {
    union: "/assets/images/icons/union.png",
    frame371Props: frame3711Data,
};

export const frame3712Data = {
    text22: "技术",
    index: "STRENGTHS",
};

export const frame3652Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-36",
    frame371Props: frame3712Data,
};

export const frame37122Data = {
    frame374: "/assets/images/icons/frame-374.png",
};

export const frame36522Data = {
    union: "/assets/images/icons/union.png",
    frame3712Props: frame37122Data,
};

export const frame36531Data = {
    union: "/assets/images/icons/union-3.png",
};

export const lang1Data = {
    vector140: "/assets/images/icons/vector-140.png",
};

export const xButton1Data = {
    steam: "投递简历",
    buttonType: "resume"
};

export const frame5281Data = {
    xButtonProps: xButton1Data,
};

export const xButton2Data = {
    steam: "投递简历",
    buttonType: "resume"
};

export const frame5282Data = {
    className: "frame-5-1-2",
    xButtonProps: xButton2Data,
};

export const xButton3Data = {
    steam: "投递简历",
};

export const frame5283Data = {
    className: "frame-5-1-3",
    xButtonProps: xButton3Data,
};

export const xButton4Data = {
    steam: "投递简历",
};

export const frame5284Data = {
    xButtonProps: xButton4Data,
};

export const xButton5Data = {
    steam: "投递简历",
};

export const frame5285Data = {
    className: "frame-5-1-5",
    xButtonProps: xButton5Data,
};

export const xButton6Data = {
    steam: "投递简历",
};

export const frame5286Data = {
    className: "frame-5-1",
    xButtonProps: xButton6Data,
};

export const frame5431Data = {
    text20: "技术",
    gameGpt: "GAMEGPT",
    chatHaruhi: "Chat Haruhi",
};

export const frame5432Data = {
    text20: "麦琪的花园",
    gameGpt: "Steam",
    chatHaruhi: "Bilibili",
};

export const x7ContactsData = {
    frame475: "/assets/images/illustrations/image-2400x2400--2-----1.png",
    rectangle365: "/assets/images/illustrations/rectangle-365-2.png",
    image2400X240021: "/assets/images/illustrations/image-2400x2400--2-----1-2.png",
    image2400X240023: "/assets/images/illustrations/image-2400x2400--2-----3-2.png",
    frame480: "/assets/images/icons/frame-480.png",
    frame479: "/assets/images/icons/frame-479.png",
    text3: "联系我们",
    frame528: "/assets/images/illustrations/property-1-default.png",
    vector2: "/assets/images/icons/vector-3.png",
    vector3: "/assets/images/icons/vector-4.png",
    text4: "邮箱",
    contactAutogameAi: "CONTACT@AUTOGAME.AI",
    vector199: "/assets/images/icons/vector-199.png",
    frame530: "/assets/images/illustrations/property-1-default.png",
    vector4: "/assets/images/icons/vector-3.png",
    text5: "@麦琪的花园",
    steam_Icon_Logo1: "/assets/images/illustrations/subtract-4.png",
    steam: "Steam 主页",
    myimage11: "/assets/images/illustrations/myimage-1.png",
    frame529: "/assets/images/illustrations/property-1-default.png",
    vector5: "/assets/images/icons/vector-3.png",
    text6: "@麦琪的花园",
    bilibiliIcon1: "/assets/images/illustrations/bilibili-icon-1.png",
    bilibili: "Bilibili 频道",
    myimage12: "/assets/images/illustrations/myimage-1.png",
    text7: "在招岗位",
    overlapGroup12: "/assets/images/icons/frame-594.png",
    text22: "让每个灵感，都成为游戏.",
    copyright: "© 2025 AUTOGAME",
    text23: "中",
    en: "EN",
    frame3651Props: frame3651Data,
    frame3652Props: frame3652Data,
    frame3652Props2: frame36522Data,
    frame3653Props: frame36531Data,
    langProps: lang1Data,
    frame5281Props: frame5281Data,
    frame5282Props: frame5282Data,
    frame5283Props: frame5283Data,
    frame5284Props: frame5284Data,
    frame5285Props: frame5285Data,
    frame5286Props: frame5286Data,
    frame5431Props: frame5431Data,
    frame5432Props: frame5432Data,
};

export const bgData = {
    frame478: "/assets/images/icons/frame-478.png",
    frame526: "/assets/images/icons/frame-526.png",
};

export const frame5001Data = {
    text3: "环境生成",
    union: "/assets/images/icons/union-8.png",
    text4: "生成与真实场景映射的虚拟环境",
};

export const frame5002Data = {
    text3: "角色生成",
    union: "/assets/images/icons/union-9.png",
    text4: "形象、动画、背景故事、数值、能力 ETC.",
};

export const frame5003Data = {
    text3: "角色行为",
    union: "/assets/images/icons/union-9.png",
    text4: "行为数逻辑+大语言模型驱动的 AI AGENT 行为",
};

export const frame5004Data = {
    text3: "实时语音合成",
    union: "/assets/images/icons/union-9.png",
    text4: "BERT-VITS2, FISHSPEECH",
};

export const xButton7Data = {
    steam: "了解详情 /Steam/",
    className: "button-1",
    buttonType: "details"
};

export const contentData = {
    subtract1: "/assets/images/illustrations/subtract.png",
    ai1: "一键创建<br />可深度互动的AI游伴",
    frame5231: "/assets/images/icons/frame-523.png",
    text8: "基于关键词定向检索角色知识，结合游戏世界观「生成角色」",
    subtract2: "/assets/images/illustrations/subtract-1.png",
    text9: "更强<br />情绪价值",
    frame5232: "/assets/images/icons/frame-523.png",
    ga: "GA 感知环境并编排角色行为，创造玩家「专属的特殊情节」",
    subtract3: "/assets/images/illustrations/subtract-2.png",
    ai2: "真正的<br />游戏具身AI",
    frame5233: "/assets/images/icons/frame-523-2.png",
    chatbot: "有故事、有个性、能互动、会说话的人工智能，「比 CHATBOT更极致」",
};

export const frame3714Data = {
    text22: "首页",
    index: "INDEX",
};

export const frame3654Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-36-1-1",
    frame371Props: frame3714Data,
};

export const frame3715Data = {
    text22: "技术",
    index: "STRENGTHS",
};

export const frame3655Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-36-1",
    frame371Props: frame3715Data,
};

export const frame37123Data = {
    frame374: "/assets/images/icons/frame-374.png",
};

export const frame36523Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-367-1",
    frame3712Props: frame37123Data,
};

export const frame36532Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-369-1",
};

export const lang2Data = {
    vector140: "/assets/images/icons/vector-140.png",
};

export const x4MagiscapesData = {
    image2400X240021: "/assets/images/illustrations/image-2400x2400--2-----1.png",
    frame475: "/assets/images/illustrations/image-2400x2400--2-----1.png",
    rectangle365: "/assets/images/illustrations/rectangle-365.png",
    image2400X240023: "/assets/images/illustrations/image-2400x2400--2-----3.png",
    magiscapes: "/assets/images/illustrations/magiscapes.png",
    text1: "麦琪的花园",
    frame495: "/assets/images/icons/union-16.png",
    union1: "/assets/images/icons/union-7.png",
    ai: "全球首款像素风「AI沙盒」游戏",
    text2: "由大语言模型驱动。玩家可以「实时生成」喜爱的冒险伙伴，并「完全自主」定义其形象、人设、外貌、语音和行为。",
    t_Grasslanddepression_11: "/assets/images/illustrations/t-grasslanddepression-1-1.png",
    t_Grass_21: "/assets/images/illustrations/t-grass-2-1.png",
    t_Snow_4: "/assets/images/illustrations/t-snow-4.png",
    x11: "/assets/images/illustrations/--1-1.png",
    t_BrownDesert_1: "/assets/images/illustrations/t-brown-desert-1.png",
    t_Cactus_21: "/assets/images/illustrations/t-cactus-2-1.png",
    maskGroup1: "/assets/images/illustrations/mask-group.png",
    maskGroup2: "/assets/images/illustrations/mask-group-1.png",
    x12: "/assets/images/logo/------1.png",
    x20240823142701: "/assets/images/illustrations/--20240823-142701.gif",
    x20240823142706: "/assets/images/illustrations/--20240823-142706.gif",
    frame512: "/assets/images/icons/frame-512.png",
    qq455876449: "QQ交流群: 455876449",
    frame: "/assets/images/icons/frame-2.png",
    frame515: "/assets/images/icons/frame-515.png",
    t_Aurelia_Avatar1: "/assets/images/illustrations/t-aurelia-avatar-1.png",
    overlapGroup: "/assets/images/illustrations/t--dialog-box-1-1.png",
    text10: "我把我们所有珍贵的回忆都收集在这里了。",
    union2: "/assets/images/icons/union-12.png",
    bGProps: bgData,
    frame5001Props: frame5001Data,
    frame5002Props: frame5002Data,
    frame5003Props: frame5003Data,
    frame5004Props: frame5004Data,
    xButtonProps: xButton7Data,
    contentProps: contentData,
    frame3651Props: frame3654Data,
    frame3652Props: frame3655Data,
    frame3652Props2: frame36523Data,
    frame3653Props: frame36532Data,
    langProps: lang2Data,
};

export const bg2Data = {
    frame_475: "/assets/images/icons/frame-475.png",
    image_2400X2400_2__1: "/assets/images/illustrations/image-2400x2400--2-----1.png",
    rectangle_365: "/assets/images/illustrations/rectangle-365.png",
    vector_2: "/assets/images/icons/vector.png",
    image_2400X2400_2__3: "/assets/images/illustrations/image-2400x2400--2-----3.png",
};

export const frame3716Data = {
    text22: "首页",
    index: "INDEX",
};

export const frame3656Data = {
    union: "/assets/images/icons/union.png",
    className: "frame_36-1",
    frame371Props: frame3716Data,
};

export const frame3717Data = {
    text22: "技术",
    index: "STRENGTHS",
};

export const frame3657Data = {
    union: "/assets/images/icons/union-3.png",
    className: "frame_36",
    frame371Props: frame3717Data,
};

export const frame37124Data = {
    frame374: "/assets/images/icons/frame-374.png",
};

export const frame36524Data = {
    union: "/assets/images/icons/union.png",
    className: "frame_367",
    frame3712Props: frame37124Data,
};

export const frame36533Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-369-2",
};

export const lang3Data = {
    vector140: "/assets/images/icons/vector-140.png",
};

export const frame4851Data = {
    chat_Haruhi: "图：CHAT HARUHI蓝图",
};

export const scrollAnimation222Data = {
    className: "scroll-animation-1",
};

export const x3Strengths01Data = {
    frame_477: "/assets/images/icons/frame-477.png",
    chatharuhi: "/assets/images/illustrations/chatharuhi.png",
    subtract_3: "/assets/images/illustrations/subtract-3.png",
    strengths_2: "STRENGTHS",
    chat_Haruhi: "CHAT HARUHI",
    text15: "在游戏中实现\"具身智能\"",
    spanText1: "1/. 通过大语言模型构建个性化、高情绪价值供给的游戏 NPC<br />2/. 有效组织 NPC 记忆，精准模仿特定动漫或电视角色的语气和知识<br />3/. 基于少量真实对话，生成高质量的虚拟角色语料库<br />",
    spanText2: "(团队核心成员为知名开源 Agent 项目 Chat Huruhi 作者之一)",
    rectangle_381: "/assets/images/illustrations/rectangle-381.png",
    httpsArxivOrgAbs230809597: "HTTPS://ARXIV.ORG/ABS/2308.09597",
    rectangle_381_2: "/assets/images/illustrations/rectangle-381.png",
    httpsGithubComL: "HTTPS://GITHUB.COM/LC1332/CHAT-HARUHI-SUZUMIYA",
    vector_192: "/assets/images/icons/vector-192.png",
    frame_481: "/assets/images/icons/frame-481.png",
    frame_488: "/assets/images/icons/frame-488.png",
    union: "/assets/images/icons/union-12.png",
    bG2Props: bg2Data,
    frame3651Props: frame3656Data,
    frame3652Props: frame3657Data,
    frame3652Props2: frame36524Data,
    frame3653Props: frame36533Data,
    langProps: lang3Data,
    frame485Props: frame4851Data,
    scrollAnimation22Props: scrollAnimation222Data,
};

export const bg3Data = {
    frame475: "/assets/images/icons/frame-475.png",
    image2400X240021: "/assets/images/illustrations/image-2400x2400--2-----1.png",
    rectangle365: "/assets/images/illustrations/rectangle-365.png",
    image2400X240023: "/assets/images/illustrations/image-2400x2400--2-----3.png",
};

export const frame3718Data = {
    text22: "首页",
    index: "INDEX",
};

export const frame3658Data = {
    union: "/assets/images/icons/union-13.png",
    className: "frame-36-2-1",
    frame371Props: frame3718Data,
};

export const frame3719Data = {
    text22: "技术",
    index: "STRENGTHS",
};

export const frame3659Data = {
    union: "/assets/images/icons/union-3.png",
    className: "frame-36-2",
    frame371Props: frame3719Data,
};

export const frame37125Data = {
    frame374: "/assets/images/icons/frame-374-10.png",
};

export const frame36525Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-367-2",
    frame3712Props: frame37125Data,
};

export const frame36534Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-369-2",
};

export const lang4Data = {
    vector140: "/assets/images/icons/vector-140.png",
};

export const frame4852Data = {
    chat_Haruhi: "图：框架概述",
};

export const scrollAnimation223Data = {
    className: "scroll-animation-2",
};

export const x2Strengths01Data = {
    frame488: "/assets/images/icons/frame-488.png",
    frame477: "/assets/images/icons/frame-477.png",
    gameGptImg: "/assets/images/illustrations/gamegpt-1.png",
    subtract: "/assets/images/illustrations/subtract.png",
    strengths: "STRENGTHS",
    gameGpt: "GAME GPT",
    aiagent: "全球首个提出面向AI开发游戏的Agent框架",
    x1MultiAgent: "1/. 自研 MULTI-AGENT 系统，多智能体协同处理，优化游戏代码生成中的冗余和幻觉<br />2/. 封装高度注释、高度解耦的游戏代码设计思路，实现在游戏引擎中生成高质量游戏代码<br />3/. 针对生成式 AL 研发，提升其游戏开发中的任务理解和代码组织能力",
    httpsArxivOrgAbs231008067: "HTTPS://ARXIV.ORG/ABS/2310.08067",
    vector192: "/assets/images/icons/vector-192.png",
    arxivLogoOneColorWhite1: "/assets/images/illustrations/image-27.png",
    image27: "/assets/images/icons/union-1.png",
    bGProps: bg3Data,
    frame3651Props: frame3658Data,
    frame3652Props: frame3659Data,
    frame3652Props2: frame36525Data,
    frame3653Props: frame36534Data,
    langProps: lang4Data,
    frame3651Props2: frame4852Data,
    langProps2: scrollAnimation223Data,
};

export const card3Data = {
    frame347: "/assets/images/icons/frame-347-3.png",
    design: "/assets/images/illustrations/design-3.png",
    frame352: "/assets/images/icons/frame-352-3.png",
    vector2: "/assets/images/icons/vector-38.png",
    frame357: "/assets/images/icons/frame-357-3.png",
    vector3: "/assets/images/icons/vector-22.png",
    vector4: "/assets/images/icons/vector-23.png",
};

export const card22Data = {
    card3Props: card3Data,
};

export const frame3762Data = {
    className: "frame-400",
};

export const frame3763Data = {
    className: "frame-424",
};

export const frame3764Data = {
    className: "frame-382",
};

export const frame3765Data = {
    className: "frame-401",
};

export const frame3766Data = {
    className: "frame-425",
};

export const frame3767Data = {
    className: "frame-388",
};

export const frame3768Data = {
    className: "frame-402",
};

export const frame3769Data = {
    className: "frame-426",
};

export const frame37610Data = {
    className: "frame-394",
};

export const frame37611Data = {
    className: "frame-403",
};

export const frame37612Data = {
    className: "frame-427",
};

export const frame37613Data = {
    className: "frame-378",
};

export const frame37614Data = {
    className: "frame-404",
};

export const frame37615Data = {
    className: "frame-428",
};

export const frame37616Data = {
    className: "frame-383",
};

export const frame37617Data = {
    className: "frame-405",
};

export const frame37618Data = {
    className: "frame-429",
};

export const frame37619Data = {
    className: "frame-389",
};

export const frame37620Data = {
    className: "frame-406",
};

export const frame37621Data = {
    className: "frame-430",
};

export const frame37622Data = {
    className: "frame-395",
};

export const frame37623Data = {
    className: "frame-407",
};

export const frame37624Data = {
    className: "frame-431",
};

export const frame37625Data = {
    className: "frame-380",
};

export const frame37626Data = {
    className: "frame-408",
};

export const frame37627Data = {
    className: "frame-432",
};

export const frame37628Data = {
    className: "frame-384",
};

export const frame37629Data = {
    className: "frame-409",
};

export const frame37630Data = {
    className: "frame-433",
};

export const frame37631Data = {
    className: "frame-390",
};

export const frame37632Data = {
    className: "frame-410",
};

export const frame37633Data = {
    className: "frame-434",
};

export const frame37634Data = {
    className: "frame-396",
};

export const frame37635Data = {
    className: "frame-411",
};

export const frame37636Data = {
    // 根据项目需要补充对应属性
    className: "frame-435",
};

export const frame3772Data = {
    className: "frame-412",
};

export const frame3773Data = {
    className: "frame-436",
};

export const frame3774Data = {
    className: "frame-385",
};

export const frame3775Data = {
    className: "frame-413",
};

export const frame3776Data = {
    className: "frame-437",
};

export const frame3777Data = {
    className: "frame-391",
};

export const frame3778Data = {
    className: "frame-414",
};

export const frame3779Data = {
    className: "frame-438",
};

export const frame37710Data = {
    className: "frame-397",
};

export const frame37711Data = {
    className: "frame-415",
};

export const frame37712Data = {
    className: "frame-439",
};

export const frame37713Data = {
    className: "frame-379",
};

export const frame37714Data = {
    className: "frame-416",
};

export const frame37715Data = {
    className: "frame-440",
};

export const frame37716Data = {
    className: "frame-386",
};

export const frame37717Data = {
    className: "frame-417",
};

export const frame37718Data = {
    className: "frame-441",
};

export const frame37719Data = {
    className: "frame-392",
};

export const frame37720Data = {
    className: "frame-418",
};

export const frame37721Data = {
    className: "frame-442",
};

export const frame37722Data = {
    className: "frame-398",
};

export const frame37723Data = {
    className: "frame-419",
};

export const frame37724Data = {
    className: "frame-443",
};

export const frame37725Data = {
    className: "frame-381",
};

export const frame37726Data = {
    className: "frame-420",
};

export const frame37727Data = {
    className: "frame-444",
};

export const frame37728Data = {
    className: "frame-387",
};

export const frame37729Data = {
    className: "frame-421",
};

export const frame37730Data = {
    className: "frame-445",
};

export const frame37731Data = {
    className: "frame-393",
};

export const frame37732Data = {
    className: "frame-422",
};

export const frame37733Data = {
    className: "frame-446",
};

export const frame37734Data = {
    className: "frame-399",
};

export const frame37735Data = {
    className: "frame-423",
};

export const frame37736Data = {
    className: "frame-447",
};

export const beltData = {
    frame3761Props: frame3762Data,
    frame3762Props: frame3763Data,
    frame3763Props: frame3764Data,
    frame3764Props: frame3765Data,
    frame3765Props: frame3766Data,
    frame3766Props: frame3767Data,
    frame3767Props: frame3768Data,
    frame3768Props: frame3769Data,
    frame3769Props: frame37610Data,
    frame37610Props: frame37611Data,
    frame37611Props: frame37612Data,
    frame37612Props: frame37613Data,
    frame37613Props: frame37614Data,
    frame37614Props: frame37615Data,
    frame37615Props: frame37616Data,
    frame37616Props: frame37617Data,
    frame37617Props: frame37618Data,
    frame37618Props: frame37619Data,
    frame37619Props: frame37620Data,
    frame37620Props: frame37621Data,
    frame37621Props: frame37622Data,
    frame37622Props: frame37623Data,
    frame37623Props: frame37624Data,
    frame37624Props: frame37625Data,
    frame37625Props: frame37626Data,
    frame37626Props: frame37627Data,
    frame37627Props: frame37628Data,
    frame37628Props: frame37629Data,
    frame37629Props: frame37630Data,
    frame37630Props: frame37631Data,
    frame37631Props: frame37632Data,
    frame37632Props: frame37633Data,
    frame37633Props: frame37634Data,
    frame37634Props: frame37635Data,
    frame37635Props: frame37636Data,
    frame3771Props: frame3772Data,
    frame3772Props: frame3773Data,
    frame3773Props: frame3774Data,
    frame3774Props: frame3775Data,
    frame3775Props: frame3776Data,
    frame3776Props: frame3777Data,
    frame3777Props: frame3778Data,
    frame3778Props: frame3779Data,
    frame3779Props: frame37710Data,
    frame37710Props: frame37711Data,
    frame37711Props: frame37712Data,
    frame37712Props: frame37713Data,
    frame37713Props: frame37714Data,
    frame37714Props: frame37715Data,
    frame37715Props: frame37716Data,
    frame37716Props: frame37717Data,
    frame37717Props: frame37718Data,
    frame37718Props: frame37719Data,
    frame37719Props: frame37720Data,
    frame37720Props: frame37721Data,
    frame37721Props: frame37722Data,
    frame37722Props: frame37723Data,
    frame37723Props: frame37724Data,
    frame37724Props: frame37725Data,
    frame37725Props: frame37726Data,
    frame37726Props: frame37727Data,
    frame37727Props: frame37728Data,
    frame37728Props: frame37729Data,
    frame37729Props: frame37730Data,
    frame37730Props: frame37731Data,
    frame37731Props: frame37732Data,
    frame37732Props: frame37733Data,
    frame37733Props: frame37734Data,
    frame37734Props: frame37735Data,
    frame37735Props: frame37736Data,
};

export const frame449Data = {
    rectangle324: "/assets/images/illustrations/rectangle-324.png",
    rectangle329: "/assets/images/illustrations/rectangle-329-4.png",
    rectangle325: "/assets/images/illustrations/rectangle-325-3.png",
    rectangle328: "/assets/images/illustrations/rectangle-328-3.png",
    frame448: "/assets/images/icons/frame-448-3.png",
    rectangle326: "/assets/images/illustrations/rectangle-326-3.png",
    rectangle327: "/assets/images/illustrations/rectangle-327.png",
    vector142: "/assets/images/icons/vector-142-3.png",
    subtract: "/assets/images/illustrations/subtract-8.png",
    beltProps: beltData,
};

export const indicator1Data = {
    src: "/assets/images/icons/union.png",
};

export const indicator2Data = {
    src: "/assets/images/icons/union.png",
};

export const indicator3Data = {
    src: "/assets/images/icons/union.png",
};

export const indicator4Data = {
    src: "/assets/images/icons/union.png",
};

export const indicaterData = {
    indicator1Props: indicator1Data,
    indicator2Props: indicator2Data,
    indicator3Props: indicator3Data,
    indicator4Props: indicator4Data,
};

export const frame37110Data = {
    text22: "首页",
    index: "INDEX",
};

export const frame36510Data = {
    union: "/assets/images/icons/union-3.png",
    className: "frame-36-3-1",
    frame371Props: frame37110Data,
};

export const frame37111Data = {
    text22: "技术",
    index: "STRENGTHS",
};

export const frame36511Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-36-3",
    frame371Props: frame37111Data,
};

export const frame37126Data = {
    frame374: "/assets/images/icons/frame-374-10.png",
};

export const frame36526Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-367-3",
    frame3712Props: frame37126Data,
};

export const frame36535Data = {
    union: "/assets/images/icons/union.png",
    className: "frame-369-3",
};

export const lang5Data = {
    vector140: "/assets/images/icons/vector-140.png",
};

export const frame458Data = {
    src: "/assets/images/icons/union-69.png",
};

export const frame4641Data = {
    vector181: "/assets/images/icons/vector-181-4.png",
    vector182: "/assets/images/icons/vector-182.png",
};

export const frame4642Data = {
    vector181: "/assets/images/icons/vector-181-5.png",
    vector182: "/assets/images/icons/vector-182-1.png",
    className: "frame-46",
};

export const frame465Data = {
    frame4641Props: frame4641Data,
    frame4642Props: frame4642Data,
};

export const frame471Data = {
    src: "/assets/images/icons/frame-462-2.png",
};

export const scrollAnimation224Data = {
    className: "scroll-animation-3",
};

export const x1Index2Data = {
    rectangle365: "/assets/images/illustrations/rectangle-365.png",
    frame475: "/assets/images/icons/frame-475-5.png",
    image2400X240021: "/assets/images/illustrations/image-2400x2400--2-----1-2.png",
    image2400X240023: "/assets/images/illustrations/image-2400x2400--2-----3.png",
    logo: "/assets/images/logo/logo-2.png",
    text47: "让每个灵感，都成为游戏.",
    card2Props: card22Data,
    frame449Props: frame449Data,
    indicater2Props: indicaterData,
    frame3651Props: frame36510Data,
    frame3652Props: frame36511Data,
    frame3652Props2: frame36526Data,
    frame3653Props: frame36535Data,
    langProps: lang5Data,
    frame458Props: frame458Data,
    frame465Props: frame465Data,
    frame471Props: frame471Data,
    indicater2Props2: scrollAnimation224Data,
};
