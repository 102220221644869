<template>
  <div :class="[`frame-377`, className || ``]">
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
    <div class="rectangle-3-2"></div>
  </div>
</template>

<script>
export default {
  name: "Frame377",
  props: ["className"],
};
</script>

<style>
.frame-377 {
  align-items: center;
  display: inline-flex;
  gap: 0.95px;
  left: 0;
  position: absolute;
  top: 454px;
}

.rectangle-3-2 {
  background-color: var(--heavy-metal);
  border-radius: 34px;
  height: 5.73px;
  position: relative;
  width: 1.66px;
}

.frame-377.frame-412 {
  top: 286px;
}

.frame-377.frame-436 {
  top: 118px;
}

.frame-377.frame-385 {
  top: 412px;
}

.frame-377.frame-413 {
  top: 244px;
}

.frame-377.frame-437 {
  top: 76px;
}

.frame-377.frame-391 {
  top: 370px;
}

.frame-377.frame-414 {
  top: 202px;
}

.frame-377.frame-438 {
  top: 34px;
}

.frame-377.frame-397 {
  top: 328px;
}

.frame-377.frame-415 {
  top: 160px;
}

.frame-377.frame-439 {
  top: -8px;
}

.frame-377.frame-379 {
  top: 440px;
}

.frame-377.frame-416 {
  top: 272px;
}

.frame-377.frame-440 {
  top: 104px;
}

.frame-377.frame-386 {
  top: 398px;
}

.frame-377.frame-417 {
  top: 230px;
}

.frame-377.frame-441 {
  top: 62px;
}

.frame-377.frame-392 {
  top: 356px;
}

.frame-377.frame-418 {
  top: 188px;
}

.frame-377.frame-442 {
  top: 20px;
}

.frame-377.frame-398 {
  top: 314px;
}

.frame-377.frame-419 {
  top: 146px;
}

.frame-377.frame-443 {
  top: -22px;
}

.frame-377.frame-381 {
  top: 426px;
}

.frame-377.frame-420 {
  top: 258px;
}

.frame-377.frame-444 {
  top: 90px;
}

.frame-377.frame-387 {
  top: 384px;
}

.frame-377.frame-421 {
  top: 216px;
}

.frame-377.frame-445 {
  top: 48px;
}

.frame-377.frame-393 {
  top: 342px;
}

.frame-377.frame-422 {
  top: 174px;
}

.frame-377.frame-446 {
  top: 6px;
}

.frame-377.frame-399 {
  top: 300px;
}

.frame-377.frame-423 {
  top: 132px;
}

.frame-377.frame-447 {
  top: -36px;
}
</style>
