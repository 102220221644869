<template>
  <div class="bg-2">
    <div class="overlap-group1-1">
      <div class="rectangle-389"></div>
      <div class="rectangle-390"></div>
      <div class="rectangle-398"></div>
      <div class="rectangle-391"></div>
      <div class="rectangle-392"></div>
      <img class="frame-478-1" :src="frame478" alt="Frame 478" />
    </div>
    <div class="rectangle-393"></div>
    <div class="rectangle-394"></div>
    <div class="rectangle-395"></div>
    <div class="rectangle-396"></div>
    <div class="rectangle-397"></div>
    <img class="frame-526" :src="frame526" alt="Frame 526" />
  </div>
</template>

<script>
export default {
  name: "Bg",
  props: ["frame478", "frame526"],
};
</script>

<style>
.bg-2 {
  align-items: flex-end;
  display: flex;
  height: 220px;
  left: 0;
  min-width: 1925px;
  padding: 0 2px;
  position: absolute;
  top: 681px;
}

.overlap-group1-1 {
  height: 220px;
  margin-bottom: 0;
  position: relative;
  width: 958px;
}

.rectangle-389 {
  background-color: var(--dove-gray);
  height: 7px;
  left: 11px;
  position: absolute;
  top: 176px;
  width: 53px;
}

.rectangle-390 {
  background-color: var(--dove-gray);
  height: 7px;
  left: 69px;
  position: absolute;
  top: 176px;
  width: 6px;
}

.rectangle-398 {
  background-color: var(--dove-gray);
  height: 7px;
  left: 916px;
  position: absolute;
  top: 176px;
  width: 42px;
}

.rectangle-391 {
  background-color: var(--dove-gray);
  height: 7px;
  left: 79px;
  position: absolute;
  top: 176px;
  width: 31px;
}

.rectangle-392 {
  background-color: var(--dove-gray);
  height: 7px;
  left: 112px;
  position: absolute;
  top: 176px;
  width: 4px;
}

.frame-478-1 {
  height: 220px;
  left: 0;
  position: absolute;
  top: 0;
  width: 935px;
}

.rectangle-393 {
  background-color: var(--dove-gray);
  height: 7px;
  margin-bottom: 37.49px;
  margin-left: 4px;
  width: 7px;
}

.rectangle-394 {
  background-color: var(--dove-gray);
  height: 7px;
  margin-bottom: 37.49px;
  margin-left: 5px;
  width: 22px;
}

.rectangle-395 {
  background-color: var(--dove-gray);
  height: 7px;
  margin-bottom: 37.49px;
  margin-left: 5px;
  width: 4px;
}

.rectangle-396 {
  background-color: var(--dove-gray);
  height: 7px;
  margin-bottom: 37.49px;
  margin-left: 5px;
  width: 61px;
}

.rectangle-397 {
  background-color: var(--dove-gray);
  height: 7px;
  margin-bottom: 37.49px;
  margin-left: 5px;
  width: 7px;
}

.frame-526 {
  height: 7px;
  margin-bottom: 37.49px;
  margin-left: 5px;
  width: 832px;
}
</style>
