<template>
  <div class="container-center-horizontal">
    <div class="x1-index-2 screen">
      <div class="overlap-group4-1">
        <div class="bg-5">
          <div class="overlap-group-20">
            <img class="rectangle-365-3" :src="rectangle365" alt="Rectangle 365" />
            <div class="ellipse-98-3"></div>
            <img class="frame-475-3" :src="frame475" alt="Frame 475" /><img
              class="image-2400x2400-2-1-3"
              :src="image2400X240021"
              alt="Image 2400x2400 (2)  1"
            /><img
              class="overlap-group-item-4"
              src="/assets/images/icons/vector.png"
              alt="Vector"
            />
            <img class="overlap-group-item-4" :src="image2400X240023" alt="Image 2400x2400 (2)  3" />
          </div>
        </div>
        <div class="overlap-group3-1">
          <card22 :card3Props="card2Props.card3Props" />
          <frame449 v-bind="frame449Props" />
          <img class="logo" :src="logo" alt="AutoGame公司标志" />
        </div>
        <div class="indicarer">
          <indicater
            :indicator1Props="indicater2Props.indicator1Props"
            :indicator2Props="indicater2Props.indicator2Props"
            :indicator3Props="indicater2Props.indicator3Props"
            :indicator4Props="indicater2Props.indicator4Props"
          />
        </div>
        <div class="frame-460">
          <div class="frame-459">
            <div class="title">
              <div class="frame-473">
                <h1 class="text-44 valign-text-middle pingfangsc-medium-white-50px">{{ text47 }}</h1>
                <frame458 :src="frame458Props.src" />
              </div>
            </div>
            <frame465 :frame4641Props="frame465Props.frame4641Props" :frame4642Props="frame465Props.frame4642Props" />
          </div>
          <frame466 />
        </div>
        <frame467 />
        <frame471 :src="frame471Props.src" />
        <scroll-animation22 :className="indicater2Props2.className" />
      </div>
    </div>
  </div>
</template>

<script>
import Card22 from "./Card22";
import Frame449 from "./Frame449";
import Indicater from "./Indicater";
import Frame365 from "./Frame365";
import Frame3652 from "./Frame3652";
import Frame3653 from "./Frame3653";
import Lang from "./Lang";
import Frame458 from "./Frame458";
import Frame465 from "./Frame465";
import Frame466 from "./Frame466";
import Frame467 from "./Frame467";
import Frame471 from "./Frame471";
import ScrollAnimation22 from "./ScrollAnimation22";
export default {
  name: "X1Index2",
  components: {
    Card22,
    Frame449,
    Indicater,
    Frame365,
    Frame3652,
    Frame3653,
    Lang,
    Frame458,
    Frame465,
    Frame466,
    Frame467,
    Frame471,
    ScrollAnimation22,
  },
  props: [
    "rectangle365",
    "frame475",
    "image2400X240021",
    "image2400X240023",
    "logo",
    "text47",
    "card2Props",
    "frame449Props",
    "indicater2Props",
    "frame3651Props",
    "frame3652Props",
    "frame3652Props2",
    "frame3653Props",
    "langProps",
    "frame458Props",
    "frame465Props",
    "frame471Props",
    "indicater2Props2",
  ],
};
</script>

<style>
.x1-index-2 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 1080px;
  overflow: hidden;
  width: 1920px;
}

.overlap-group4-1 {
  height: 1920px;
  margin-top: -420px;
  position: relative;
  width: 2274px;
}

.bg-5 {
  align-items: flex-start;
  background-color: var(--eerie-black);
  display: flex;
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.overlap-group-20 {
  height: 3767px;
  margin-top: -1877.37px;
  position: relative;
  width: 1920px;
}

.rectangle-365-3 {
  height: 1044px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2297px;
  width: 1920px;
}

.ellipse-98-3 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0.15) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 763.82px/1883.62px;
  height: 3767px;
  left: 196px;
  position: absolute;
  top: 0;
  width: 1528px;
}

.frame-475-3 {
  height: 538px;
  left: 23px;
  position: absolute;
  top: 2839px;
  width: 1897px;
}

.image-2400x2400-2-1-3 {
  height: 622px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2755px;
  width: 1920px;
}

.overlap-group-item-4 {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 2297px;
  width: 1920px;
}

.overlap-group3-1 {
  height: 1142px;
  left: 301px;
  position: absolute;
  top: 109px;
  width: 405px;
}

.logo {
  height: 162px;
  left: 0;
  position: absolute;
  top: 673px;
  width: 262px;
}

.indicarer {
  display: flex;
  height: 1080px;
  left: 64px;
  overflow: hidden;
  position: absolute;
  top: 420px;
  width: 24px;
}

.frame-460 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 100px;
  left: 960px;
  position: absolute;
  top: 754px;
  width: 1314px;
}

.frame-459 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.title {
  display: flex;
  height: 133px;
  position: relative;
  width: 1314px;
}

.frame-473 {
  display: flex;
  flex-direction: column;
  gap: 11px;
  height: 133px;
  position: relative;
  width: 1314px;
}

.text-44 {
  height: 66px;
  letter-spacing: -1px;
  line-height: 66px;
  margin-top: 43px;
  white-space: nowrap;
  width: 553px;
  font-family: "PingFang SC Medium", "PingFang SC", -apple-system, "SF Pro SC", "SF Pro Text", "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
}
</style>

