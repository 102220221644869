<template>
  <div class="card">
    <card3
      :frame347="card3Props.frame347"
      :design="card3Props.design"
      :frame352="card3Props.frame352"
      :vector2="card3Props.vector2"
      :frame357="card3Props.frame357"
      :vector3="card3Props.vector3"
      :vector4="card3Props.vector4"
    />
  </div>
</template>

<script>
import Card3 from "./Card3";
export default {
  name: "Card22",
  components: {
    Card3,
  },
  props: ["card3Props"],
};
</script>

<style>
.card {
  display: flex;
  height: 544px;
  left: 17px;
  position: absolute;
  top: 598px;
  width: 388px;
}
</style>
