import { render, staticRenderFns } from "./Belt.vue?vue&type=template&id=013f84cf"
import script from "./Belt.vue?vue&type=script&lang=js"
export * from "./Belt.vue?vue&type=script&lang=js"
import style0 from "./Belt.vue?vue&type=style&index=0&id=013f84cf&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports