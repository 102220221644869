<template>
  <footer :class="['footer', { 'footer-fixed': isFixed }]">
    <div class="overlap-group12" :style="{ 'background-image': 'url(' + overlapGroup12 + ')' }">
      <div class="frame-547">
        <div class="frame-545">
          <div class="frame-361-1">
            <img
              class="x1"
              src="/assets/images/logo/---1-6.svg"
              alt="1"
            />
          </div>
          <div class="text-22 pingfangsc-regular-normal-bon-jour-14px">{{ text22 }}</div>
        </div>
        <div class="frame-549 pingfangsc-regular-normal-bon-jour-14px">
          <div class="copyright">{{ copyright }}</div>
          <div class="frame-550">
            <div class="text-23">{{ text23 }}</div>
            <div class="en">{{ en }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="frame-546">
      <frame543
        :text20="frame5431Props.text20"
        :gameGpt="frame5431Props.gameGpt"
        :chatHaruhi="frame5431Props.chatHaruhi"
      />
      <frame543
        :text20="frame5432Props.text20"
        :gameGpt="frame5432Props.gameGpt"
        :chatHaruhi="frame5432Props.chatHaruhi"
      />
    </div>
  </footer>
</template>

<script>
import Frame543 from "./Frame543";

export default {
  name: "Footer",
  components: {
    Frame543,
  },
  props: {
    overlapGroup12: String,
    text22: String,
    copyright: String,
    text23: String,
    en: String,
    frame5431Props: Object,
    frame5432Props: Object,
    isFixed: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.footer {
  align-items: center;
  background-color: var(--eerie-black);
  display: flex;
  flex-direction: row;
  gap: 547px;
  height: 261px;
  justify-content: flex-start;
  padding: 0 83px;
  width: 1920px;
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.overlap-group12 {
  align-items: flex-start;
  align-self: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 254px;
  min-width: 820px;
  padding: 53px 76px;
}

.frame-547 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 64px;
  position: relative;
}

.frame-545 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.frame-361-1 {
  align-self: stretch;
  display: flex;
  height: 23px;
  position: relative;
  width: 100%;
}

.x1 {
  height: 23.05px;
  width: 230px;
}

.text-22 {
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame-549 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
  width: 100%;
}

.copyright,
.text-23,
.en {
  letter-spacing: -0.28px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame-550 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.frame-546 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 49px;
  position: relative;
  width: 310px;
}
</style>