<template>
  <a :href="getButtonLink()" target="_blank" class="button-link">
    <div :class="[`button-2`, className || ``, buttonType === 'resume' ? 'resume-button-style' : '']">
      <div class="button-content">
        <frame454 :class="{ 'icon-hover': isHovered }" />
        <div class="steam-1 pingfangsc-medium-white-18px" :class="{ 'text-hover': isHovered }">{{ steam }}</div>
      </div>
    </div>
  </a>
</template>

<script>
import Frame454 from "./Frame454";
export default {
  name: "XButton",
  components: {
    Frame454,
  },
  props: ["steam", "className", "buttonType"],
  data() {
    return {
      isHovered: false
    };
  },
  mounted() {
    this.$el.addEventListener('mouseenter', this.onMouseEnter);
    this.$el.addEventListener('mouseleave', this.onMouseLeave);
  },
  beforeDestroy() {
    this.$el.removeEventListener('mouseenter', this.onMouseEnter);
    this.$el.removeEventListener('mouseleave', this.onMouseLeave);
  },
  methods: {
    onMouseEnter() {
      this.isHovered = true;
    },
    onMouseLeave() {
      this.isHovered = false;
    },
    getButtonLink() {
      if (this.buttonType === "resume") {
        return "mailto:hr@autogame.ai";
      } else if (this.buttonType === "details" || this.buttonType === "tryGame") {
        return "https://store.steampowered.com/app/2990190/_/";
      } else if (this.buttonType === "steam") {
        return "https://store.steampowered.com/app/2990190/MagiScapes/";
      } else {
        if (this.steam === "投递简历") {
          return "mailto:hr@autogame.ai";
        } else if (this.steam === "了解详情" || this.steam === "试玩") {
          return "https://store.steampowered.com/app/2990190/_/";
        } else {
          return "https://store.steampowered.com/app/2990190/MagiScapes/";
        }
      }
    }
  }
};
</script>

<style>
.button-link {
  text-decoration: none;
  display: inline-block;
}

.button-2 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(58.87, 49.61, 92.34) 0%,
    rgb(42, 116, 232) 38%,
    rgb(42, 116, 232) 98.02%,
    rgb(246, 246, 236) 100%
  );
  box-shadow: -8px 8px 1px #00000029;
  display: inline-flex;
  justify-content: center;
  padding: 12px 35px;
  position: relative;
  height: 48px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-2:hover {
  background: #ffffff !important;
  box-shadow: -8px 8px 3px #00000040;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  transform: translateX(-3px);
}

.steam-1 {
  letter-spacing: -0.54px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  margin-left: 15px;
  font-size: 18px;
  transition: color 0.3s ease;
}

.text-hover {
  color: #000000 !important;
}

.icon-hover {
  filter: brightness(0);
}

.button-2.button-1 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(58.87, 49.61, 92.34) 0%,
    rgb(42, 116, 232) 38%,
    rgb(42, 116, 232) 98.02%,
    rgb(246, 246, 236) 100%
  );
  box-shadow: -8px 8px 1px #00000029;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 12px 35px;
  position: relative;
  width: auto;
  height: 48px;
  transition: all 0.3s ease;
}

/* 为投递简历按钮添加特殊样式 */
.resume-button-style {
  position: relative;
  left: 280px; /* 向右偏移 */
  top: 30px; /* 向下偏移 */
}
</style>
