import Vue from "vue";
import Router from "vue-router";
import X7Contacts from "./components/X7Contacts";
import X4Magiscapes from "./components/X4Magiscapes";
import X3Strengths01 from "./components/X3Strengths01";
import X2Strengths01 from "./components/X2Strengths01";
import X1Index2 from "./components/X1Index2";
import HomePage from "./components/HomePage";
import { x7ContactsData, x4MagiscapesData, x3Strengths01Data, x2Strengths01Data, x1Index2Data } from "./data";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomePage,
    },
    {
      path: "/7-contacts",
      component: X7Contacts,
      props: { ...x7ContactsData },
    },
    {
      path: "/4-magiscapes",
      component: X4Magiscapes,
      props: { ...x4MagiscapesData },
    },
    {
      path: "/3u95strengths01",
      component: X3Strengths01,
      props: { ...x3Strengths01Data },
    },
    {
      path: "/2-strengths01",
      component: X2Strengths01,
      props: { ...x2Strengths01Data },
    },
    {
      path: "/1-index-2",
      component: X1Index2,
      props: { ...x1Index2Data },
    },
  ],
});
