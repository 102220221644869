<template>
  <div class="view"></div>
</template>

<script>
export default {
  name: "TJeLVi",
};
</script>

<style>
.view {
  background-image: url(/assets/images/illustrations/view-1-8.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 132px;
  position: absolute;
  top: 0;
  width: 60px;
}
</style>

