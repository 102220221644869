<template>
  <div :class="[`frame-46-1`, className || ``]">
    <div class="overlap-group-23">
      <div class="frame-361-6"></div>
      <div class="rectangle-372"></div>
      <img class="vector-181" :src="vector181" alt="Vector 181" /><img
        class="vector-182"
        :src="vector182"
        alt="Vector 182"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Frame464",
  props: ["vector181", "vector182", "className"],
};
</script>

<style>
.frame-46-1 {
  height: 42.21px;
  position: relative;
  width: 127.6px;
}

.overlap-group-23 {
  height: 42px;
  position: relative;
  width: 128px;
}

.frame-361-6 {
  background-image: url(/assets/images/logo/---1-18.svg);
  background-size: 100% 100%;
  height: 5px;
  left: 37px;
  position: absolute;
  top: 19px;
  width: 54px;
}

.rectangle-372 {
  border: 0.45px solid;
  border-color: var(--gallery);
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 128px;
}

.vector-181 {
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 29px;
}

.vector-182 {
  height: 42px;
  left: 98px;
  position: absolute;
  top: 0;
  width: 29px;
}

.frame-46-1.frame-46 .frame-361-6 {
  background-image: url(/assets/images/logo/---1-18.svg);
}
</style>

