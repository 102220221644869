<template>
  <div class="nav">
    <div class="frame-361"></div>
    <div class="frame-362">
      <div class="nav-item" @click="navigateTo(1)">
        <div class="frame-371">
          <img v-if="currentSection === 1" class="union-3" src="/assets/images/icons/union-7.png" alt="首页导航指示符" />
          <div class="frame-373">
            <div class="text-1 pingfangsc-semibold-gallery-16px">首页</div>
          </div>
          <div class="index dinpro-bold-sonic-silver-16px">INDEX</div>
        </div>
      </div>
      <div class="nav-item" @click="navigateTo(currentSection === 3 ? 2 : 3)">
        <div class="frame-371">
          <img v-if="currentSection === 2 || currentSection === 3" class="union-3" src="/assets/images/icons/union-7.png" alt="技术导航指示符" />
          <div class="frame-373">
            <div class="text-1 pingfangsc-semibold-gallery-16px">技术</div>
          </div>
          <div class="index dinpro-bold-sonic-silver-16px">STRENGTHS</div>
        </div>
      </div>
      <div class="nav-item" @click="navigateTo(4)">
        <div class="frame-371">
          <img v-if="currentSection === 4" class="union-3" src="/assets/images/icons/union-7.png" alt="麦琪的花园导航指示符" />
          <div class="frame-373">
            <div class="text-1 pingfangsc-semibold-gallery-16px">麦琪的花园</div>
            <img class="frame-374" src="/assets/images/icons/frame-374.png" alt="麦琪的花园图标" />
          </div>
          <div class="index dinpro-bold-sonic-silver-16px">MAGISCAPES</div>
        </div>
      </div>
      <div class="nav-item" @click="navigateTo(5)">
        <div class="frame-371">
          <img v-if="currentSection === 5" class="union-3" src="/assets/images/icons/union-7.png" alt="联系我们导航指示符" />
          <div class="frame-373">
            <div class="text-1 pingfangsc-semibold-gallery-16px">联系我们</div>
          </div>
          <div class="index dinpro-bold-sonic-silver-16px">CONTACTS</div>
        </div>
      </div>
      <div class="lang-container">
        <lang :vector140="navData.langProps.vector140" />
      </div>
    </div>
  </div>
</template>

<script>
import Lang from "./Lang";

export default {
  name: "NavHeader",
  components: {
    Lang,
  },
  props: {
    navData: {
      type: Object,
      required: true
    },
    currentSection: {
      type: Number,
      default: 1
    }
  },
  methods: {
    navigateTo(sectionNum) {
      this.$emit('change-section', sectionNum);
    }
  }
};
</script>

<style>
.nav {
  -webkit-backdrop-filter: blur(30px) brightness(100%);
  backdrop-filter: blur(30px) brightness(100%);
  background-color: var(--licorice);
  display: flex;
  height: 88px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.frame-361 {
  align-self: center;
  background-image: url(/assets/images/logo/logo.svg);
  background-size: 100% 100%;
  height: 23.05px;
  margin-left: 80px;
  margin-top: 0;
  width: 230px;
}

.frame-362 {
  align-items: center;
  display: inline-flex;
  gap: 40px;
  height: 43px;
  justify-content: flex-end;
  margin-right: 80px;
  margin-top: 20px;
  position: relative;
  width: 863px;
}

.nav-item {
  position: relative;
  cursor: pointer;
}

.frame-371 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
}

.frame-373 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.text-1 {
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  color: white;
  font-size: 14px;
  font-family: "PingFang SC Semibold", "PingFang SC", -apple-system, "SF Pro SC", "SF Pro Text", "Helvetica Neue", sans-serif !important;
  font-weight: 600 !important;
}

.index {
  letter-spacing: -0.48px;
  line-height: normal;
  position: relative;
  width: 100px;
  color: #999;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 2px;
  font-family: "DINPro-Bold", Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
}

.union-3 {
  height: 36px;
  position: absolute;
  left: -40px;
  top: 5px;
  width: 30px;
}

.frame-374 {
  height: 25.63px;
  margin-bottom: -1.81px;
  margin-right: -3.31px;
  margin-top: -1.81px;
  position: relative;
  width: 25.63px;
}

.lang-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  border-left: 1px solid rgba(255,255,255,0.2);
  padding-left: 20px;
  height: 20px;
}
</style> 