<template>
  <div class="frame-54">
    <div class="text-2-2 pingfangsc-semibold-bon-jour-14px">{{ text20 }}</div>
    <a :href="getGameGptLink()" target="_blank" class="footer-link">
      <div class="gamegpt pingfangsc-regular-normal-bon-jour-14px">{{ gameGpt }}</div>
    </a>
    <a :href="getChatHaruhiLink()" target="_blank" class="footer-link">
      <div class="chat-haruhi pingfangsc-regular-normal-bon-jour-14px">{{ chatHaruhi }}</div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Frame543",
  props: ["text20", "gameGpt", "chatHaruhi"],
  methods: {
    getGameGptLink() {
      if (this.text20 === "技术") {
        return "https://arxiv.org/abs/2310.08067"; // GAMEGPT论文链接
      } else {
        return "https://store.steampowered.com/app/2990190/MagiScapes/"; // Steam链接
      }
    },
    getChatHaruhiLink() {
      if (this.text20 === "技术") {
        return "https://github.com/LC1332/Chat-Haruhi-Suzumiya"; // Chat Haruhi GitHub链接
      } else {
        return "https://space.bilibili.com/1775149662"; // Bilibili频道链接
      }
    }
  }
};
</script>

<style>
.frame-54 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 93px;
}

.text-2-2 {
  align-self: stretch;
  letter-spacing: -0.28px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.gamegpt,
.chat-haruhi,
.steam-3,
.bilibili-1 {
  align-self: stretch;
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
}

.footer-link {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s ease;
}

.footer-link:hover {
  opacity: 0.8;
}

/* 添加semibold字体样式 */
.pingfangsc-semibold-bon-jour-14px {
  color: var(--bon-jour);
  font-family: var(--font-family-pingfangsc-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
</style>
