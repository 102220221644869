<template>
  <div :class="[`frame-367`, className || ``]">
    <img class="union-1" :src="union" alt="Union" /><frame3712 :frame374="frame3712Props.frame374" />
  </div>
</template>

<script>
import Frame3712 from "./Frame3712";
export default {
  name: "Frame3652",
  components: {
    Frame3712,
  },
  props: ["union", "className", "frame3712Props"],
};
</script>

<style>
.frame-367 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.union-1 {
  height: 36px;
  margin-left: -17444px;
  margin-top: -6896.5px;
  position: relative;
  width: 30px;
}

.frame-367.frame-367-1 .union-1 {
  height: 36px;
  margin-left: unset;
  margin-top: unset;
  width: 30px;
}

.frame-367.frame_367 .union-1 {
  margin-top: -4541.5px;
}

.frame-367.frame-367-2 .union-1 {
  margin-top: -3375.5px;
}

.frame-367.frame-367-3 .union-1 {
  margin-left: -17436px;
  margin-top: -2186.5px;
}
</style>
