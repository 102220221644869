<template>
  <div class="indicator" :style="{ 'background-image': 'url(' + src + ')' }"></div>
</template>

<script>
export default {
  name: "Indicator",
  props: ["src"],
};
</script>

<style>
.indicator {
  background-size: 100% 100%;
  height: 349.53px;
  margin-left: 8px;
  margin-top: 650px;
  width: 7.98px;
}
</style>
