<template>
  <div class="bg-3">
    <img class="frame_475" :src="frame_475" alt="Frame_475" />
    <div class="flex-row-1">
      <img class="image_2400x2400_2__1" :src="image_2400X2400_2__1" alt="Image_2400x2400_(2)__1" />
      <div class="overlap-group-18">
        <img class="rectangle_365" :src="rectangle_365" alt="Rectangle_365" />
        <div class="ellipse_98"></div>
        <img class="overlap-group-item-2" :src="vector_2" alt="Vector_2" /><img
          class="overlap-group-item-2"
          :src="image_2400X2400_2__3"
          alt="Image_2400x2400_(2)__3"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bg2",
  props: ["frame_475", "image_2400X2400_2__1", "rectangle_365", "vector_2", "image_2400X2400_2__3"],
};
</script>

<style>
.bg-3 {
  align-items: flex-start;
  background-color: var(--eerie-black);
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 1920px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1920px;
}

.frame_475 {
  height: 605px;
  margin-left: -15873px;
  margin-top: -3140px;
  width: 2335px;
}

.flex-row-1 {
  align-items: flex-start;
  display: flex;
  gap: 14155px;
  height: 4421px;
  margin-left: -16075px;
  min-width: 17995px;
}

.image_2400x2400_2__1 {
  height: 1920px;
  object-fit: cover;
  width: 1920px;
}

.overlap-group-18 {
  align-self: flex-end;
  height: 3767px;
  position: relative;
  width: 1920px;
}

.rectangle_365 {
  height: 1044px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2297px;
  width: 1920px;
}

.ellipse_98 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0.15) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 763.82px/1883.62px;
  height: 3767px;
  left: 196px;
  position: absolute;
  top: 0;
  width: 1528px;
}

.overlap-group-item-2 {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 2297px;
  width: 1920px;
}
</style>
