import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "../styleguide.css"
import "../globals.css"
import "./assets/fonts.css"
import "./assets/font-override.css"

// 导入Font Awesome组件
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQq } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// 添加图标到库中
library.add(faQq)

// 注册全局组件
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router
  }).$mount("#app");