<template>
  <div class="container-center-horizontal">
    <div class="x3u95strengths01 screen">
      <div class="overlap-group1-2">
        <bg2
          :frame_475="bG2Props.frame_475"
          :image_2400X2400_2__1="bG2Props.image_2400X2400_2__1"
          :rectangle_365="bG2Props.rectangle_365"
          :vector_2="bG2Props.vector_2"
          :image_2400X2400_2__3="bG2Props.image_2400X2400_2__3"
        />
        <img class="frame_477" :src="frame_477" alt="Frame_477" />
        <img class="chatharuhi" :src="chatharuhi" alt="Chat Haruhi - 具身智能AI角色系统" />
        <div class="frame_484">
          <div class="frame_478">
            <div class="frame_490">
              <div class="frame_489"><img class="subtract_3" :src="subtract_3" alt="Subtract_3" /></div>
              <div class="strengths_2 valign-text-middle">
                <span class="dinpro-bold">STRENGTHS</span>
              </div>
            </div>
            <div class="frame-1">
              <div class="chat_-haruhi valign-text-middle montserrat-bold-white-44px">{{ chat_Haruhi }}</div>
              <h1 class="text-38 pingfangsc-normal-white-50px">{{ text15 }}</h1>
            </div>
          </div>
          <div class="frame_482">
            <p class="x1_">
              <span class="pingfangsc-regular-normal-white-16px">{{ spanText1 }}</span
              ><span class="span1">{{ spanText2 }}</span>
            </p>
            <div class="frame-1">
              <a href="https://arxiv.org/abs/2308.09597" target="_blank" class="frame-link">
                <div class="frame_48">
                  <img class="rectangle_381" :src="rectangle_381" alt="Rectangle_381" /><frame492 />
                  <div class="https dinpro-bold-white-16px">{{ httpsArxivOrgAbs230809597 }}</div>
                </div>
              </a>
              <a href="https://github.com/LC1332/Chat-Haruhi-Suzumiya" target="_blank" class="frame-link">
                <div class="frame_48">
                  <img class="rectangle_381" :src="rectangle_381_2" alt="Rectangle_381_2" />
                  <frame4922 />
                  <div class="https dinpro-bold-white-16px">{{ httpsGithubComL }}</div>
                </div>
              </a>
            </div>
            <img class="vector_192" :src="vector_192" alt="Vector_192" />
          </div>
        </div>
        <div class="frame_486">
          <img class="frame_481" :src="frame_481" alt="Frame_481" /><frame485
            :chat_Haruhi="frame485Props.chat_Haruhi"
          />
        </div>
        <div class="frame_487"><img class="frame_488" :src="frame_488" alt="Frame_488" /></div>
        <scroll-animation22 :className="scrollAnimation22Props.className" />
        <img class="union-8" :src="union" alt="Union" />
      </div>
    </div>
  </div>
</template>

<script>
import Bg2 from "./Bg2";
import Frame365 from "./Frame365";
import Frame3652 from "./Frame3652";
import Frame3653 from "./Frame3653";
import Lang from "./Lang";
import Frame492 from "./Frame492";
import Frame4922 from "./Frame4922";
import Frame485 from "./Frame485";
import ScrollAnimation22 from "./ScrollAnimation22";
export default {
  name: "X3Strengths01",
  components: {
    Bg2,
    Frame365,
    Frame3652,
    Frame3653,
    Lang,
    Frame492,
    Frame4922,
    Frame485,
    ScrollAnimation22,
  },
  props: [
    "frame_477",
    "chatharuhi",
    "subtract_3",
    "strengths_2",
    "chat_Haruhi",
    "text15",
    "spanText1",
    "spanText2",
    "rectangle_381",
    "httpsArxivOrgAbs230809597",
    "rectangle_381_2",
    "httpsGithubComL",
    "vector_192",
    "frame_481",
    "frame_488",
    "union",
    "bG2Props",
    "frame3651Props",
    "frame3652Props",
    "frame3652Props2",
    "frame3653Props",
    "langProps",
    "frame485Props",
    "scrollAnimation22Props",
  ],
};
</script>

<style>
.x3u95strengths01 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 1080px;
  overflow: hidden;
  width: 1920px;
}

.overlap-group1-2 {
  height: 1920px;
  margin-top: -420px;
  position: relative;
  width: 1920px;
}

.frame_477 {
  height: 409px;
  left: 0;
  position: absolute;
  top: 1091px;
  width: 1920px;
}

.chatharuhi {
  height: 650px;
  left: 160px;
  position: absolute;
  top: 516px;
  width: 650px;
}

.frame_484 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 152px;
  left: 160px;
  position: absolute;
  top: 695px;
  width: 650px;
}

.frame_478 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.frame_490 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame_489 {
  align-self: stretch;
  display: flex;
  height: 23px;
  position: relative;
  width: 100%;
}

.subtract_3 {
  height: 23.05px;
  margin-left: 0.1px;
  margin-top: -1.5px;
  width: 36.31px;
}

.strengths_2 {
  letter-spacing: -2.4px;
  line-height: normal;
  opacity: 0.6;
  position: relative;
  width: fit-content;
  color: var(--alto);
  font-size: 24px;
}

.dinpro-regular {
  font-family: "DINPro", Helvetica, Arial, sans-serif !important;
  font-weight: normal !important;
}

.dinpro-bold {
  font-family: "DINPro-Bold", Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
}

.frame-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.chat_-haruhi {
  align-self: stretch;
  letter-spacing: -0.88px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.text-38 {
  letter-spacing: -1px;
  line-height: 50px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame_482 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 31px;
  position: relative;
  width: 100%;
}

.x1_ {
  align-self: stretch;
  color: transparent;
  font-family: var(--font-family-pingfang_sc-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
}

.span1 {
  color: #808080;
}

.frame_48 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.rectangle_381 {
  height: 19px;
  position: relative;
  width: 8px;
}

.https {
  flex: 1;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
}

.vector_192 {
  height: 3px;
  margin-bottom: -1.5px;
  position: relative;
  width: 163px;
}

.frame_486 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 960px;
  position: absolute;
  top: 683px;
  width: 800px;
}

.frame_481 {
  align-self: stretch;
  height: 574px;
  position: relative;
  width: 100%;
}

.frame_487 {
  display: flex;
  height: 53px;
  left: 1500px;
  position: absolute;
  top: 615px;
  width: 53px;
}

.frame_488 {
  height: 102px;
  margin-left: -540px;
  margin-top: 5px;
  width: 799.5px;
}

.union-8 {
  height: 350px;
  left: 64px;
  position: absolute;
  top: 1071px;
  width: 8px;
}

.frame-link {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s ease;
}

.frame-link:hover {
  opacity: 0.8;
}
</style>
