<template>
  <div :class="[`frame-36-4`, className || ``]">
    <img class="union" :src="union" alt="Union" /><frame371
      :text22="frame371Props.text22"
      :index="frame371Props.index"
    />
  </div>
</template>

<script>
import Frame371 from "./Frame371";
export default {
  name: "Frame365",
  components: {
    Frame371,
  },
  props: ["union", "className", "frame371Props"],
};
</script>

<style>
.frame-36-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.union {
  height: 36px;
  margin-left: -17052px;
  margin-top: -6896.5px;
  position: relative;
  width: 30px;
}

.frame-36-4.frame-36 .union {
  margin-left: -17248px;
}

.frame-36-4.frame-36-1-1 .union {
  margin-top: -5707.5px;
}

.frame-36-4.frame-36-1 .union {
  margin-left: -17248px;
  margin-top: -5707.5px;
}

.frame-36-4.frame_36-1 .union {
  margin-top: -4541.5px;
}

.frame-36-4.frame_36 .union,
.frame-36-4.frame-36-2 .union,
.frame-36-4.frame-36-3-1 .union {
  height: 36px;
  margin-left: unset;
  margin-top: unset;
  width: 30px;
}

.frame-36-4.frame-36-2-1 .union {
  margin-top: -3375.5px;
}

.frame-36-4.frame-36-3 .union {
  margin-left: -17240px;
  margin-top: -2186.5px;
}
</style>
