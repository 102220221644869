<template>
  <div class="frame-449">
    <belt v-bind="beltProps" />
    <div class="frame-375">
      <div class="overlap-group-22">
        <img class="rectangle-324" :src="rectangle324" alt="Rectangle 324" /><img
          class="rectangle-329-1"
          :src="rectangle329"
          alt="Rectangle 329"
        /><img class="rectangle-325" :src="rectangle325" alt="Rectangle 325" /><img
          class="rectangle-328"
          :src="rectangle328"
          alt="Rectangle 328"
        />
      </div>
      <div class="overlap-group1-5">
        <img class="frame-448" :src="frame448" alt="Frame 448" /><img
          class="rectangle-326"
          :src="rectangle326"
          alt="Rectangle 326"
        /><img class="rectangle-327" :src="rectangle327" alt="Rectangle 327" /><img
          class="vector-142"
          :src="vector142"
          alt="Vector 142"
        /><img class="subtract-4" :src="subtract" alt="Subtract" />
      </div>
    </div>
  </div>
</template>

<script>
import Belt from "./Belt";
export default {
  name: "Frame449",
  components: {
    Belt,
  },
  props: [
    "rectangle324",
    "rectangle329",
    "rectangle325",
    "rectangle328",
    "frame448",
    "rectangle326",
    "rectangle327",
    "vector142",
    "subtract",
    "beltProps",
  ],
};
</script>

<style>
.frame-449 {
  display: flex;
  flex-direction: column;
  gap: 0.3px;
  height: 626px;
  left: 186px;
  position: absolute;
  top: 0;
  width: 83px;
}

.frame-375 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 0.2px;
  min-height: 158.15px;
  width: 82.5px;
}

.overlap-group-22 {
  height: 46px;
  margin-left: -4px;
  margin-top: -3px;
  position: relative;
  width: 91px;
}

.rectangle-324 {
  height: 12px;
  left: 6px;
  position: absolute;
  top: 34px;
  width: 79px;
}

.rectangle-329-1 {
  height: 9px;
  left: 6px;
  position: absolute;
  top: 36px;
  width: 78px;
}

.rectangle-325 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 91px;
}

.rectangle-328 {
  height: 37px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 89px;
}

.overlap-group1-5 {
  height: 115px;
  margin-left: 1.55px;
  position: relative;
  width: 79px;
}

.frame-448 {
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 79px;
}

.rectangle-326 {
  height: 15px;
  left: 33px;
  position: absolute;
  top: 27px;
  width: 13px;
}

.rectangle-327 {
  height: 18px;
  left: 26px;
  position: absolute;
  top: 26px;
  width: 27px;
}

.vector-142 {
  height: 11px;
  left: 15px;
  position: absolute;
  top: 69px;
  width: 10px;
}

.subtract-4 {
  height: 73px;
  left: 21px;
  position: absolute;
  top: 42px;
  width: 38px;
}
</style>
