<template>
  <div class="frame-467">
    <div class="steam-4 pingfangsc-regular-normal-white-14px">
      <span class="pingfangsc-regular-normal-white-14px">* </span>
      <a href="https://store.steampowered.com/app/2990190/_/" target="_blank" class="play-link">
        <span class="span1-1 pingfangsc-semibold-white-14px">点击试玩-Steam</span>
      </a>
    </div>
    <img
      class="steam_icon_logosvg-1"
      src="/assets/images/logo/steam-icon-logo-svg-1.png"
      alt="Steam_icon_logo.svg 1"
    />
  </div>
</template>

<script>
export default {
  name: "Frame467",
};
</script>

<style>
.frame-467 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 960px;
  position: absolute;
  top: 1121px;
}

.steam-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.span1-1 {
  text-decoration: underline;
  font-family: "PingFang SC Semibold", "PingFang SC", -apple-system, "SF Pro SC", "SF Pro Text", "Helvetica Neue", sans-serif !important;
  font-weight: 600 !important;
}

.steam_icon_logosvg-1 {
  height: 20px;
  object-fit: cover;
  position: relative;
  width: 20px;
}

.play-link {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.play-link:hover {
  opacity: 0.8;
}
</style>

