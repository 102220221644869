<template>
  <div :class="[`frame-369`, className || ``]"><img class="union-2" :src="union" alt="Union" /><frame3713 /></div>
</template>

<script>
import Frame3713 from "./Frame3713";
export default {
  name: "Frame3653",
  components: {
    Frame3713,
  },
  props: ["union", "className"],
};
</script>

<style>
.frame-369 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.union-2 {
  height: 36px;
  position: relative;
  width: 30px;
}

.frame-369.frame-369-1 .union-2 {
  height: 36px;
  margin-left: -17640px;
  margin-top: -5707.5px;
  width: 30px;
}

.frame-369.frame_369 .union-2 {
  height: 36px;
  margin-left: -17640px;
  margin-top: -4541.5px;
  width: 30px;
}

.frame-369.frame-369-2 .union-2 {
  height: 36px;
  margin-left: -17640px;
  margin-top: -3375.5px;
  width: 30px;
}

.frame-369.frame-369-3 .union-2 {
  height: 36px;
  margin-left: -17632px;
  margin-top: -2186.5px;
  width: 30px;
}
</style>
