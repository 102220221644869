<template>
  <div class="indicater">
    <div class="frame-5-3"><indicator :src="indicator1Props.src" /></div>
    <div class="frame-5-3"><indicator :src="indicator2Props.src" /></div>
    <div class="frame-5-3"><indicator :src="indicator3Props.src" /></div>
    <div class="frame-5-3"><indicator :src="indicator4Props.src" /></div>
  </div>
</template>

<script>
import Indicator from "./Indicator";
export default {
  name: "Indicater",
  components: {
    Indicator,
  },
  props: ["indicator1Props", "indicator2Props", "indicator3Props", "indicator4Props"],
};
</script>

<style>
.indicater {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 4320px;
  margin-top: 1080px;
  position: relative;
  width: 24px;
}

.frame-5-3 {
  align-self: stretch;
  display: flex;
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
</style>
