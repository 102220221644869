<template>
  <div :class="[`scroll-animation`, className || ``]"><scroll /></div>
</template>

<script>
import Scroll from "./Scroll";
export default {
  name: "ScrollAnimation22",
  components: {
    Scroll,
  },
  props: ["className"],
};
</script>

<style>
.scroll-animation {
  display: flex;
  height: 35px;
  left: 942px;
  position: absolute;
  top: 1405px;
  width: 39px;
}

.scroll-animation.scroll-animation-1,
.scroll-animation.scroll-animation-2,
.scroll-animation.scroll-animation-3 {
  left: 940px;
}
</style>
