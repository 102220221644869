<template>
  <div class="frame_492_2">
    <div class="frame_551">
      <img
        class="frame-2"
        src="/assets/images/icons/frame.png"
        alt="Frame"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Frame4922",
};
</script>

<style>
.frame_492_2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.frame_551 {
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 53px;
}

.frame-2 {
  height: 24px;
  margin-left: 1px;
  width: 24px;
}
</style>

