<template>
  <div class="bg-4">
    <div class="flex-col">
      <img class="frame-475-2" :src="frame475" alt="Frame 475" /><img
        class="image-2400x2400-2-1-2"
        :src="image2400X240021"
        alt="Image 2400x2400 (2)  1"
      />
    </div>
    <div class="overlap-group-19">
      <img class="rectangle-365-2" :src="rectangle365" alt="Rectangle 365" />
      <div class="ellipse-98-2"></div>
      <img
        class="overlap-group-item-3"
        src="/assets/images/icons/vector.png"
        alt="Vector"
      />
      <img class="overlap-group-item-3" :src="image2400X240023" alt="Image 2400x2400 (2)  3" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Bg3",
  props: ["frame475", "image2400X240021", "rectangle365", "image2400X240023"],
};
</script>

<style>
.bg-4 {
  align-items: flex-start;
  background-color: var(--eerie-black);
  display: flex;
  gap: 13538px;
  height: 1920px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1920px;
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: -16075px;
  margin-top: -1974px;
  min-height: 2529px;
  width: 2537px;
}

.frame-475-2 {
  align-self: flex-end;
  height: 605px;
  width: 2335px;
}

.image-2400x2400-2-1-2 {
  height: 1920px;
  object-fit: cover;
  width: 1920px;
}

.overlap-group-19 {
  height: 3767px;
  margin-top: -1877.37px;
  position: relative;
  width: 1920px;
}

.rectangle-365-2 {
  height: 1044px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2297px;
  width: 1920px;
}

.ellipse-98-2 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0.15) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 763.82px/1883.62px;
  height: 3767px;
  left: 196px;
  position: absolute;
  top: 0;
  width: 1528px;
}

.overlap-group-item-3 {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 2297px;
  width: 1920px;
}
</style>

