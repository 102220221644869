<template>
  <div class="frame-465">
    <frame464 :vector181="frame4641Props.vector181" :vector182="frame4641Props.vector182" />
    <frame464
      :vector181="frame4642Props.vector181"
      :vector182="frame4642Props.vector182"
      :className="frame4642Props.className"
    />
  </div>
</template>

<script>
import Frame464 from "./Frame464";
export default {
  name: "Frame465",
  components: {
    Frame464,
  },
  props: ["frame4641Props", "frame4642Props"],
};
</script>

<style>
.frame-465 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}
</style>
