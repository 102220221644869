<template>
  <div class="frame-466">
    <div class="overlap-group2-2" @click="openVideoModal">
      <main-button />
      <t-je-l-vi />
    </div>
    
    <!-- 视频模态窗口 -->
    <div v-if="showVideoModal" class="video-modal-overlay" @click="closeVideoModal">
      <div class="video-modal" @click.stop>
        <div class="video-modal-header">
          <span class="video-modal-title">麦琪的花园</span>
          <span class="video-modal-close" @click="closeVideoModal">&times;</span>
        </div>
        <div class="video-modal-body">
          <video ref="videoPlayer" controls autoplay class="video-player">
            <source src="https://video.fastly.steamstatic.com/store_trailers/257053439/movie_max_vp9.webm?t=1727615834" type="video/webm">
            您的浏览器不支持视频播放。
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "./MainButton";
import TJeLVi from "./TJeLVi";
export default {
  name: "Frame466",
  components: {
    MainButton,
    TJeLVi,
  },
  data() {
    return {
      showVideoModal: false
    };
  },
  methods: {
    openVideoModal() {
      this.showVideoModal = true;
      document.body.style.overflow = 'hidden'; // 防止背景滚动
    },
    closeVideoModal() {
      this.showVideoModal = false;
      document.body.style.overflow = ''; // 恢复背景滚动
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause(); // 关闭窗口时暂停视频
      }
    }
  }
};
</script>

<style>
.frame-466 {
  height: 63px;
  position: relative;
  width: 277px;
}

.overlap-group2-2 {
  height: 112px;
  position: relative;
  top: -49px;
  cursor: pointer;
}

/* 模态窗口样式 */
.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-modal {
  background-color: #111111;
  border-radius: 0;
  width: 90%;
  max-width: 1280px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.video-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #181818;
  border-bottom: 1px solid #333;
}

.video-modal-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.video-modal-close {
  color: #999;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s;
  margin-right: 10px;
}

.video-modal-close:hover {
  color: white;
}

.video-modal-body {
  padding: 0;
}

.video-player {
  width: 100%;
  display: block;
  height: auto;
}
</style>
