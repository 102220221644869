<template>
  <div class="container-center-horizontal">
    <div class="x7-contacts screen">
      <div class="overlap-group13">
        <div class="bg">
          <img class="frame-475" :src="frame475" alt="Frame 475" />
          <div class="overlap-group-1">
            <img class="rectangle-365" :src="rectangle365" alt="Rectangle 365" />
            <div class="ellipse-98"></div>
            <img class="image-2400x2400-2-1" :src="image2400X240021" alt="Image 2400x2400 (2)  1" /><img
              class="overlap-group-item"
              src="/assets/images/icons/vector-1.png"
              alt="Vector"
            />
            <img class="overlap-group-item" :src="image2400X240023" alt="Image 2400x2400 (2)  3" />
          </div>
        </div>
        <img class="frame-480" :src="frame480" alt="Frame 480" /><img
          class="frame-479"
          :src="frame479"
          alt="Frame 479"
        />
        <div class="frame-538">
          <h1 class="text pingfangsc-normal-white-24px">{{ text3 }}</h1>
          <div class="frame-532">
            <div class="frame-5" :style="{ 'background-image': 'url(' + frame528 + ')' }">
              <a href="mailto:contact@autogame.ai" class="contact-link">
                <div class="overlap-group">
                  <img class="vector" :src="vector2" alt="Vector" /><img class="vector-1" :src="vector3" alt="Vector" />
                  <div class="frame-535">
                    <div class="text-4 pingfangsc-normal-white-22px">{{ text4 }}</div>
                  </div>
                  <div class="contactautogameai pingfangsc-regular-normal-white-18px">{{ contactAutogameAi }}</div>
                </div>
              </a>
            </div>
            <img class="vector-199" :src="vector199" alt="Vector 199" />
            <div class="frame-5" :style="{ 'background-image': 'url(' + frame530 + ')' }">
              <a href="https://store.steampowered.com/app/2990190/MagiScapes/" target="_blank" class="contact-link">
                <div class="overlap-group">
                  <img class="vector" :src="vector4" alt="Vector" />
                  <div class="text-5 pingfangsc-regular-normal-white-18px">{{ text5 }}</div>
                  <div class="frame-536">
                    <div class="frame-534">
                      <div
                        class="steam_icon_logo-1"
                        :style="{ 'background-image': 'url(' + steam_Icon_Logo1 + ')' }"
                      ></div>
                    </div>
                    <div class="steam pingfangsc-normal-white-22px">{{ steam }}</div>
                  </div>
                  <img class="my-image-1" :src="myimage11" alt="myImage 1" />
                </div>
              </a>
            </div>
            <div class="frame-5" :style="{ 'background-image': 'url(' + frame529 + ')' }">
              <a href="https://space.bilibili.com/1775149662" target="_blank" class="contact-link">
                <div class="overlap-group">
                  <img class="vector" :src="vector5" alt="Vector" />
                  <div class="text-6 pingfangsc-regular-normal-white-18px">{{ text6 }}</div>
                  <div class="frame-537">
                    <div class="frame-534-1">
                      <img class="bilibili-icon-1" :src="bilibiliIcon1" alt="Bilibili Icon 1" />
                    </div>
                    <div class="bilibili pingfangsc-normal-white-22px">{{ bilibili }}</div>
                  </div>
                  <img class="my-image-1" :src="myimage12" alt="myImage 1" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="frame-542">
          <div class="text pingfangsc-normal-white-24px">{{ text7 }}</div>
          <div class="frame-541">
            <frame528 :xButtonProps="frame5281Props.xButtonProps" />
            <frame528 :className="frame5282Props.className" :xButtonProps="frame5282Props.xButtonProps" />
            <frame528 :className="frame5283Props.className" :xButtonProps="frame5283Props.xButtonProps" />
            <frame528 :xButtonProps="frame5284Props.xButtonProps" />
            <frame528 :className="frame5285Props.className" :xButtonProps="frame5285Props.xButtonProps" />
            <frame528 :className="frame5286Props.className" :xButtonProps="frame5286Props.xButtonProps" />
          </div>
        </div>
        <Footer 
          class="footer-position"
          :overlapGroup12="overlapGroup12"
          :text22="text22"
          :copyright="copyright"
          :text23="text23"
          :en="en"
          :frame5431Props="frame5431Props"
          :frame5432Props="frame5432Props"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Frame365 from "./Frame365";
import Frame3652 from "./Frame3652";
import Frame3653 from "./Frame3653";
import Lang from "./Lang";
import Frame528 from "./Frame528";
import Frame543 from "./Frame543";
import Footer from "./Footer";

export default {
  name: "X7Contacts",
  components: {
    Frame365,
    Frame3652,
    Frame3653,
    Lang,
    Frame528,
    Frame543,
    Footer,
  },
  props: [
    "frame475",
    "rectangle365",
    "image2400X240021",
    "image2400X240023",
    "frame480",
    "frame479",
    "text3",
    "frame528",
    "vector2",
    "vector3",
    "text4",
    "contactAutogameAi",
    "vector199",
    "frame530",
    "vector4",
    "text5",
    "steam_Icon_Logo1",
    "steam",
    "myimage11",
    "frame529",
    "vector5",
    "text6",
    "bilibiliIcon1",
    "bilibili",
    "myimage12",
    "text7",
    "overlapGroup12",
    "text22",
    "copyright",
    "text23",
    "en",
    "frame3651Props",
    "frame3652Props",
    "frame3652Props2",
    "frame3653Props",
    "langProps",
    "frame5281Props",
    "frame5282Props",
    "frame5283Props",
    "frame5284Props",
    "frame5285Props",
    "frame5286Props",
    "frame5431Props",
    "frame5432Props",
  ],
};
</script>

<style>
.x7-contacts {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 2075px;
  width: 1920px;
}

.overlap-group13 {
  height: 2091px;
  margin-top: -16px;
  position: relative;
  width: 1920px;
}

.bg {
  align-items: flex-start;
  background-color: var(--eerie-black);
  display: flex;
  gap: 11818px;
  height: 1920px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1920px;
}

.frame-475 {
  height: 605px;
  margin-left: -14153px;
  margin-top: -5899px;
  width: 2335px;
}

.overlap-group-1 {
  height: 3797px;
  margin-top: -1877.37px;
  position: relative;
  width: 1920px;
}

.rectangle-365 {
  height: 1448px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1893px;
  width: 1920px;
}

.ellipse-98 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0.15) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 763.82px/1883.62px;
  height: 3767px;
  left: 196px;
  position: absolute;
  top: 0;
  width: 1528px;
}

.image-2400x2400-2-1 {
  height: 9px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3788px;
  width: 1920px;
}

.overlap-group-item {
  height: 1904px;
  left: 0;
  position: absolute;
  top: 1893px;
  width: 1920px;
}

.frame-480 {
  height: 186px;
  left: 1268px;
  position: absolute;
  top: 442px;
  width: 572px;
}

.frame-479 {
  height: 186px;
  left: 1093px;
  position: absolute;
  top: 130px;
  width: 747px;
}

.frame-538 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 159px;
  position: absolute;
  top: 170px;
}

.text {
  align-self: stretch;
  letter-spacing: -0.48px;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
}

.frame-532 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 54px 54px;
  position: relative;
}

.frame-5 {
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: -8px 12px 4px #00000040;
  height: 144px;
  position: relative;
  width: 360px;
}

.overlap-group {
  height: 144px;
  position: relative;
}

.vector {
  height: 105px;
  left: 0;
  position: absolute;
  top: 39px;
  width: 221px;
}

.vector-1 {
  height: 144px;
  left: 191px;
  position: absolute;
  top: 0;
  width: 169px;
}

.frame-535 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 34px;
  position: absolute;
  top: 21px;
  width: 58px;
}

.text-4 {
  align-self: stretch;
  letter-spacing: -0.44px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.contactautogameai,
.text-5,
.text-6 {
  left: 34px;
  letter-spacing: -0.36px;
  line-height: normal;
  position: absolute;
  top: 98px;
}

.vector-199 {
  height: 119px;
  position: relative;
  width: 1px;
}

.frame-536 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 34px;
  position: absolute;
  top: 21px;
  width: 159px;
}

.frame-534 {
  display: flex;
  height: 32.55px;
  position: relative;
  width: 32px;
}

.steam_icon_logo-1 {
  background-size: 100% 100%;
  height: 28.25px;
  margin-left: 1.7px;
  margin-top: 1.7px;
  width: 28.22px;
}

.steam,
.bilibili {
  align-self: stretch;
  letter-spacing: -0.44px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  white-space: nowrap;
}

.my-image-1 {
  height: 144px;
  left: 180px;
  position: absolute;
  top: 0;
  width: 180px;
}

.frame-537 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 34px;
  position: absolute;
  top: 21px;
  width: 111px;
}

.frame-534-1 {
  align-items: center;
  display: flex;
  height: 32.55px;
  justify-content: center;
  position: relative;
  width: 32px;
}

.bilibili-icon-1 {
  height: 37.52px;
  margin-left: 0;
  margin-top: 0.6px;
  width: 37.52px;
}

.frame-542 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 159px;
  position: absolute;
  top: 468px;
}

.frame-541 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 54px 54px;
  position: relative;
  width: 100%;
}

.footer-position {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.contact-link {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s ease;
}

.contact-link:hover {
  opacity: 0.8;
}
</style>

