<template>
  <div class="lang">
    <div class="frame-372">
      <img
        class="vector-139"
        src="/assets/images/icons/vector-139.png"
        alt="Vector 139"
      />
      <div class="overlap-group-2">
        <img class="vector-140" :src="vector140" alt="Vector 140" />
        <div class="text-2-1 pingfangsc-normal-bon-jour-16px">中</div>
        <div class="en-1 pingfangsc-normal-sonic-silver-16px">EN</div>
      </div>
      <img
        class="vector-141"
        src="/assets/images/icons/vector-141.png"
        alt="Vector 141"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Lang",
  props: ["vector140"],
};
</script>

<style>
.lang,
.lang-1,
.lang-2,
.lang-3,
.lang-4 {
  display: flex;
  height: 43px;
  justify-content: center;
  position: relative;
  width: 79px;
}

.frame-372,
.frame-372-1,
.frame_372,
.frame-372-2,
.frame-372-3 {
  align-items: flex-start;
  display: flex;
  margin-left: -1px;
  margin-top: 3px;
  min-width: 70px;
}

.vector-139,
.vector-139-1,
.vector_139,
.vector-139-2,
.vector-139-3 {
  height: 36px;
  margin-left: -1px;
  margin-top: -0.29px;
  width: 8px;
}

.overlap-group-2,
.overlap-group-3,
.overlap-group-4,
.overlap-group-5,
.overlap-group-6 {
  height: 40px;
  margin-left: 3px;
  margin-top: -2px;
  position: relative;
  width: 48px;
}

.vector-140,
.vector-140-1,
.vector_140,
.vector-140-2,
.vector-140-3 {
  height: 34px;
  left: 8px;
  position: absolute;
  top: 3px;
  width: 30px;
}

.text-2-1,
.text-35,
.text-37,
.text-40,
.text-43 {
  left: 0;
  letter-spacing: -0.48px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.en-1,
.en-2,
.en-3,
.en-4,
.en-5 {
  left: 26px;
  letter-spacing: -0.48px;
  line-height: normal;
  position: absolute;
  top: 18px;
}

.vector-141,
.vector-141-1,
.vector_141,
.vector-141-2,
.vector-141-3 {
  height: 36px;
  margin-left: 5px;
  margin-top: -0.29px;
  width: 8px;
}
</style>
