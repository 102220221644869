<template>
  <div class="belt">
    <frame376 />
    <frame376 :className="frame3761Props.className" />
    <frame376 :className="frame3762Props.className" />
    <frame376 :className="frame3763Props.className" />
    <frame376 :className="frame3764Props.className" />
    <frame376 :className="frame3765Props.className" />
    <frame376 :className="frame3766Props.className" />
    <frame376 :className="frame3767Props.className" />
    <frame376 :className="frame3768Props.className" />
    <frame376 :className="frame3769Props.className" />
    <frame376 :className="frame37610Props.className" />
    <frame376 :className="frame37611Props.className" />
    <frame376 :className="frame37612Props.className" />
    <frame376 :className="frame37613Props.className" />
    <frame376 :className="frame37614Props.className" />
    <frame376 :className="frame37615Props.className" />
    <frame376 :className="frame37616Props.className" />
    <frame376 :className="frame37617Props.className" />
    <frame376 :className="frame37618Props.className" />
    <frame376 :className="frame37619Props.className" />
    <frame376 :className="frame37620Props.className" />
    <frame376 :className="frame37621Props.className" />
    <frame376 :className="frame37622Props.className" />
    <frame376 :className="frame37623Props.className" />
    <frame376 :className="frame37624Props.className" />
    <frame376 :className="frame37625Props.className" />
    <frame376 :className="frame37626Props.className" />
    <frame376 :className="frame37627Props.className" />
    <frame376 :className="frame37628Props.className" />
    <frame376 :className="frame37629Props.className" />
    <frame376 :className="frame37630Props.className" />
    <frame376 :className="frame37631Props.className" />
    <frame376 :className="frame37632Props.className" />
    <frame376 :className="frame37633Props.className" />
    <frame376 :className="frame37634Props.className" />
    <frame376 :className="frame37635Props.className" />
    <frame377 />
    <frame377 :className="frame3771Props.className" />
    <frame377 :className="frame3772Props.className" />
    <frame377 :className="frame3773Props.className" />
    <frame377 :className="frame3774Props.className" />
    <frame377 :className="frame3775Props.className" />
    <frame377 :className="frame3776Props.className" />
    <frame377 :className="frame3777Props.className" />
    <frame377 :className="frame3778Props.className" />
    <frame377 :className="frame3779Props.className" />
    <frame377 :className="frame37710Props.className" />
    <frame377 :className="frame37711Props.className" />
    <frame377 :className="frame37712Props.className" />
    <frame377 :className="frame37713Props.className" />
    <frame377 :className="frame37714Props.className" />
    <frame377 :className="frame37715Props.className" />
    <frame377 :className="frame37716Props.className" />
    <frame377 :className="frame37717Props.className" />
    <frame377 :className="frame37718Props.className" />
    <frame377 :className="frame37719Props.className" />
    <frame377 :className="frame37720Props.className" />
    <frame377 :className="frame37721Props.className" />
    <frame377 :className="frame37722Props.className" />
    <frame377 :className="frame37723Props.className" />
    <frame377 :className="frame37724Props.className" />
    <frame377 :className="frame37725Props.className" />
    <frame377 :className="frame37726Props.className" />
    <frame377 :className="frame37727Props.className" />
    <frame377 :className="frame37728Props.className" />
    <frame377 :className="frame37729Props.className" />
    <frame377 :className="frame37730Props.className" />
    <frame377 :className="frame37731Props.className" />
    <frame377 :className="frame37732Props.className" />
    <frame377 :className="frame37733Props.className" />
    <frame377 :className="frame37734Props.className" />
    <frame377 :className="frame37735Props.className" />
  </div>
</template>

<script>
import Frame376 from "./Frame376";
import Frame377 from "./Frame377";
export default {
  name: "Belt",
  components: {
    Frame376,
    Frame377,
  },
  props: [
    "frame3761Props",
    "frame3762Props",
    "frame3763Props",
    "frame3764Props",
    "frame3765Props",
    "frame3766Props",
    "frame3767Props",
    "frame3768Props",
    "frame3769Props",
    "frame37610Props",
    "frame37611Props",
    "frame37612Props",
    "frame37613Props",
    "frame37614Props",
    "frame37615Props",
    "frame37616Props",
    "frame37617Props",
    "frame37618Props",
    "frame37619Props",
    "frame37620Props",
    "frame37621Props",
    "frame37622Props",
    "frame37623Props",
    "frame37624Props",
    "frame37625Props",
    "frame37626Props",
    "frame37627Props",
    "frame37628Props",
    "frame37629Props",
    "frame37630Props",
    "frame37631Props",
    "frame37632Props",
    "frame37633Props",
    "frame37634Props",
    "frame37635Props",
    "frame3771Props",
    "frame3772Props",
    "frame3773Props",
    "frame3774Props",
    "frame3775Props",
    "frame3776Props",
    "frame3777Props",
    "frame3778Props",
    "frame3779Props",
    "frame37710Props",
    "frame37711Props",
    "frame37712Props",
    "frame37713Props",
    "frame37714Props",
    "frame37715Props",
    "frame37716Props",
    "frame37717Props",
    "frame37718Props",
    "frame37719Props",
    "frame37720Props",
    "frame37721Props",
    "frame37722Props",
    "frame37723Props",
    "frame37724Props",
    "frame37725Props",
    "frame37726Props",
    "frame37727Props",
    "frame37728Props",
    "frame37729Props",
    "frame37730Props",
    "frame37731Props",
    "frame37732Props",
    "frame37733Props",
    "frame37734Props",
    "frame37735Props",
  ],
};
</script>

<style>
.belt {
  background-color: #3f3f3f;
  height: 467.69px;
  overflow: hidden;
  position: relative;
  width: 82.5px;
}
</style>
