<template>
  <div class="home-page">
    <NavHeader 
      :navData="navHeaderData" 
      :currentSection="currentSection" 
      @change-section="scrollToSection"
    />
    
    <div class="page-section" id="section1">
      <X1Index2 v-bind="x1Index2Data" />
    </div>
    <div class="page-section" id="section2">
      <X2Strengths01 v-bind="x2Strengths01Data" />
    </div>
    <div class="page-section" id="section3">
      <X3Strengths01 v-bind="x3Strengths01Data" />
    </div>
    <div class="page-section" id="section4">
      <X4Magiscapes v-bind="x4MagiscapesData" />
    </div>
    <div class="page-section" id="section5">
      <X7Contacts v-bind="x7ContactsData" />
    </div>
  </div>
</template>

<script>
import X1Index2 from './X1Index2.vue';
import X2Strengths01 from './X2Strengths01.vue';
import X3Strengths01 from './X3Strengths01.vue';
import X4Magiscapes from './X4Magiscapes.vue';
import X7Contacts from './X7Contacts.vue';
import NavHeader from './NavHeader.vue';
import { x1Index2Data, x2Strengths01Data, x3Strengths01Data, x4MagiscapesData, x7ContactsData } from '../data';

export default {
  name: 'HomePage',
  components: {
    X1Index2,
    X2Strengths01,
    X3Strengths01,
    X4Magiscapes,
    X7Contacts,
    NavHeader
  },
  data() {
    return {
      x1Index2Data,
      x2Strengths01Data,
      x3Strengths01Data,
      x4MagiscapesData,
      x7ContactsData,
      currentSection: 1,
      isScrolling: false,
      navHeaderData: {
        frame3651Props: x7ContactsData.frame3651Props,
        frame3652Props: x7ContactsData.frame3652Props,
        frame3652Props2: x7ContactsData.frame3652Props2,
        frame3653Props: x7ContactsData.frame3653Props,
        langProps: x7ContactsData.langProps
      }
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('wheel', this.handleWheel, { passive: false });
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('wheel', this.handleWheel);
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    scrollToSection(sectionNum) {
      this.isScrolling = true;
      const sectionId = `section${sectionNum}`;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        this.currentSection = sectionNum;
        
        // 防止连续滚动事件
        setTimeout(() => {
          this.isScrolling = false;
        }, 1000);
      }
    },
    handleScroll() {
      if (this.isScrolling) return;
      
      const sections = document.querySelectorAll('.page-section');
      const scrollPosition = window.scrollY + window.innerHeight / 3;
      
      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;
        
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          this.currentSection = index + 1;
        }
      });
    },
    handleWheel(event) {
      // 如果已经在滚动动画中，阻止继续滚动
      if (this.isScrolling) {
        event.preventDefault();
        return;
      }
      
      // 检测向上或向下滚动
      if (event.deltaY > 0 && this.currentSection < 5) {
        event.preventDefault();
        this.scrollToSection(this.currentSection + 1);
      } else if (event.deltaY < 0 && this.currentSection > 1) {
        event.preventDefault();
        this.scrollToSection(this.currentSection - 1);
      }
    },
    handleKeydown(event) {
      if (this.isScrolling) return;
      
      // 上下箭头键导航
      if (event.key === 'ArrowDown' && this.currentSection < 5) {
        event.preventDefault();
        this.scrollToSection(this.currentSection + 1);
      } else if (event.key === 'ArrowUp' && this.currentSection > 1) {
        event.preventDefault();
        this.scrollToSection(this.currentSection - 1);
      }
    }
  }
};
</script>

<style scoped>
.home-page {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.page-section {
  min-height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* 为了让页面无缝滚动，禁用浏览器默认滚动行为 */
html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}
</style> 