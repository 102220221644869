<template>
  <div class="frame-454">
    <img
      class="union-3"
      src="/assets/images/icons/union-4.png"
      alt="Union"
    />
  </div>
</template>

<script>
export default {
  name: "Frame454",
};
</script>

<style>
.frame-454,
.frame-454-1 {
  height: 40px;
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(30px, -4px);
  transition: filter 0.3s ease;
}

.union-3,
.union-4 {
  height: 28px;
  position: relative;
  width: 24px;
  margin-left: 8px;
  transition: filter 0.3s ease;
}

.icon-hover .union-3,
.icon-hover .union-4 {
  filter: brightness(0);
}
</style>

