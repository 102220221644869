<template>
  <div class="content">
    <div class="frame-518">
      <div class="frame-521"><img class="subtract" :src="subtract1" alt="Subtract" /></div>
      <div class="frame-520">
        <div class="ai-7 valign-text-middle pingfangsc-normal-black-20-5px" v-html="ai1"></div>
        <a href="https://store.steampowered.com/app/2990190/_/" target="_blank" class="detail-link">
          <img class="frame-523" :src="frame5231" alt="Frame 523" />
        </a>
      </div>
      <div class="frame-519">
        <div class="text-31 pingfangsc-regular-normal-alto-16px">{{ text8 }}</div>
      </div>
    </div>
    <div class="frame-519-1">
      <div class="frame-521"><img class="subtract-1" :src="subtract2" alt="Subtract" /></div>
      <div class="frame-520">
        <div class="text-32 valign-text-middle pingfangsc-normal-black-20-5px">{{ text9 }}</div>
        <a href="https://store.steampowered.com/app/2990190/_/" target="_blank" class="detail-link">
          <img class="frame-523-1" :src="frame5232" alt="Frame 523" />
        </a>
      </div>
      <div class="frame-519">
        <div class="ga pingfangsc-regular-normal-alto-16px">{{ ga }}</div>
      </div>
    </div>
    <div class="frame-520-1">
      <div class="frame-521"><img class="subtract-2" :src="subtract3" alt="Subtract" /></div>
      <div class="frame-520">
        <div class="ai-8 valign-text-middle pingfangsc-normal-black-20-5px">{{ ai2 }}</div>
        <a href="https://store.steampowered.com/app/2990190/_/" target="_blank" class="detail-link">
          <img class="frame-523-2" :src="frame5233" alt="Frame 523" />
        </a>
      </div>
      <div class="frame-519">
        <div class="chatbot pingfangsc-regular-normal-alto-16px">{{ chatbot }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content",
  props: [
    "subtract1",
    "ai1",
    "frame5231",
    "text8",
    "subtract2",
    "text9",
    "frame5232",
    "ga",
    "subtract3",
    "ai2",
    "frame5233",
    "chatbot",
  ],
};
</script>

<style>
.content {
  display: flex;
  flex-direction: column;
  gap: 39px;
  height: 566px;
  left: 1002px;
  position: absolute;
  top: 677px;
  width: 354px;
}

.frame-518 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 172.76px;
  margin-left: -20px;
  position: relative;
  width: 241px;
}

.frame-521 {
  display: flex;
  height: 31.76px;
  position: relative;
  width: 52.08px;
}

.subtract {
  height: 21.5px;
  margin-left: 2.4px;
  margin-top: 5.2px;
  width: 43.57px;
}

.frame-520 {
  align-items: center;
  align-self: stretch;
  background-color: var(--mahogany);
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px 10px;
  position: relative;
  width: 100%;
}

.ai-7,
.text-32,
.ai-8 {
  flex: 1;
  letter-spacing: 0;
  line-height: 25.7px;
  margin-top: -1px;
  position: relative;
}

.frame-523 {
  height: 149px;
  left: 128px;
  position: absolute;
  top: -29px;
  width: 149px;
}

.frame-519 {
  align-items: center;
  align-self: stretch;
  background-color: var(--log-cabin);
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px 10px;
  position: relative;
  width: 100%;
}

.text-31,
.ga,
.chatbot {
  flex: 1;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.frame-519-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 172.76px;
  margin-left: 20px;
  position: relative;
  width: 216px;
}

.subtract-1 {
  height: 35.83px;
  margin-left: 2.4px;
  margin-top: 5.2px;
  width: 57.49px;
}

.frame-523-1 {
  height: 149px;
  left: 103px;
  position: absolute;
  top: -29px;
  width: 149px;
}

.frame-520-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 172.76px;
  position: relative;
  width: 202px;
}

.subtract-2 {
  height: 21.32px;
  margin-left: 2.4px;
  margin-top: 5.2px;
  width: 55.69px;
}

.frame-523-2 {
  height: 149px;
  left: 89px;
  position: absolute;
  top: -28px;
  width: 149px;
}

.detail-link {
  display: inline-block;
  transition: transform 0.3s ease;
}

.detail-link:hover {
  transform: scale(1.05);
}
</style>
