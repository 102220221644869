var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"responsive-container"},[_c('div',{ref:"content",staticClass:"content",style:({ 
      transform: `scale(${_vm.scale})`,
      transformOrigin: 'center center',
      width: '1920px',
      height: '1080px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      marginLeft: '-960px',
      marginTop: '-540px'
    })},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }