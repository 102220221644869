<template>
  <div class="responsive-container" ref="container">
    <div 
      class="content" 
      ref="content"
      :style="{ 
        transform: `scale(${scale})`,
        transformOrigin: 'center center',
        width: '1920px',
        height: '1080px',
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: '-960px',
        marginTop: '-540px'
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsiveLayout",
  data() {
    return {
      scale: 1,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      resizeTimer: null,
      loaded: false
    };
  },
  created() {
    // 提前开始计算
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.calculateScale();
  },
  mounted() {
    // 强制立即执行初始缩放计算
    this.calculateScale();
    
    // 监听窗口调整事件
    window.addEventListener('resize', this.handleResize);
    
    // 使用多种方法确保页面正确缩放
    document.fonts.ready.then(() => this.calculateScale());
    window.addEventListener('load', this.onLoadComplete);
    
    // 初始加载后300ms再次计算
    setTimeout(() => {
      this.calculateScale();
    }, 300);
    
    // 1秒后再次强制刷新布局
    setTimeout(() => {
      this.calculateScale();
      this.loaded = true;
      this.forceRelayout();
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('load', this.onLoadComplete);
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
  },
  methods: {
    onLoadComplete() {
      this.calculateScale();
      // 完全加载后，强制重新布局
      this.forceRelayout();
    },
    forceRelayout() {
      // 强制DOM重新布局
      if (this.$refs.container) {
        const currentHeight = this.$refs.container.style.height;
        this.$refs.container.style.height = 'auto';
        // 触发重排
        void this.$refs.container.offsetHeight;
        this.$refs.container.style.height = currentHeight;
      }
      
      // 触发窗口的resize事件，确保所有响应式元素更新
      window.dispatchEvent(new Event('resize'));
    },
    handleResize() {
      // 使用防抖处理窗口大小改变
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
      }
      
      // 立即更新一次，提高响应速度
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.calculateScale();
      
      // 延迟再次计算，确保稳定性
      this.resizeTimer = setTimeout(() => {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.calculateScale();
        this.forceRelayout();
      }, 50);
    },
    calculateScale() {
      // 计算缩放比例，确保内容能够适应屏幕宽度
      const scaleX = this.windowWidth / 1920;
      const scaleY = this.windowHeight / 1080;
      
      // 使用较小的值以确保内容完全适应窗口
      const scale = Math.min(scaleX, scaleY);
      this.scale = scale;
    }
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a; /* RGB(48,48,48) */
  overflow: hidden;
}

body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.responsive-container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #1a1a1a;
}

.content {
  backface-visibility: hidden; /* 提高性能 */
  will-change: transform; /* 提高性能 */
}

/* 确保页面内的容器不会导致滚动 */
.container-center-horizontal {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* 确保所有页面组件正确缩放 */
.screen {
  position: relative;
  overflow: hidden;
}
</style>
