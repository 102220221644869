<template>
  <div class="frame_492">
    <img
      class="arxiv-logo-one-color-white_1"
      src="/assets/images/logo/arxiv-logo-one-color-white-1.png"
      alt="arxiv-logo-one-color-white_1"
    />
  </div>
</template>

<script>
export default {
  name: "Frame492",
};
</script>

<style>
.frame_492,
.frame-492 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.arxiv-logo-one-color-white_1,
.arxiv-logo-one-color-white-1 {
  height: 23px;
  position: relative;
  width: 53px;
}
</style>

