<template>
  <div class="frame-458"><img class="union-10" :src="src" alt="Union" /></div>
</template>

<script>
export default {
  name: "Frame458",
  props: ["src"],
};
</script>

<style>
.frame-458 {
  display: flex;
  width: 1314px;
}

.union-10 {
  height: 13px;
  width: 960px;
}
</style>
