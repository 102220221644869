<template>
  <div class="frame-471" :style="{ 'background-image': 'url(' + src + ')' }">
    <div class="frame-463">
      <div class="rectangle-369"></div>
      <div class="overlap-group-24">
        <div class="rectangle-370"></div>
        <div class="rectangle-371"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Frame471",
  props: ["src"],
};
</script>

<style>
.frame-471 {
  background-size: 100% 100%;
  display: flex;
  height: 126px;
  left: 80px;
  position: absolute;
  top: 543px;
  width: 126px;
}

.frame-463 {
  height: 44.8px;
  margin-top: 80.8px;
  overflow: hidden;
  position: relative;
  width: 44.8px;
}

.rectangle-369 {
  background-color: var(--stack);
  height: 45px;
  left: 38px;
  position: absolute;
  top: 0;
  width: 7px;
}

.overlap-group-24 {
  height: 48px;
  left: -3px;
  position: absolute;
  top: -3px;
  width: 48px;
}

.rectangle-370 {
  background-color: var(--stack);
  height: 45px;
  left: 22px;
  position: absolute;
  top: 22px;
  transform: rotate(90deg);
  width: 7px;
}

.rectangle-371 {
  background-color: var(--stack);
  height: 58px;
  left: 20px;
  position: absolute;
  top: -6px;
  transform: rotate(135deg);
  width: 7px;
}
</style>
