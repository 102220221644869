<template>
  <div :class="[`frame-5-3-1`, className || ``]">
    <div class="overlap-group-31">
      <img
        class="vector-22"
        src="/assets/images/icons/vector-7.png"
        alt="Vector"
      />
      <div class="rectangle-401-1"></div>
      <img
        class="frame-478-3"
        src="/assets/images/icons/frame-478-1.png"
        alt="Frame 478"
      />
      <div class="frame-361-11"></div>
      <div class="text-18 pingfangsc-normal-white-22px">游戏战斗策划</div>
      <div class="text-19 pingfangsc-normal-white-20px">职位要求</div>
      <div class="ai-2 pingfangsc-regular-normal-white-16px">
        明星AI沙盒游戏制作组寻找战斗策划！如果你对战斗手感和Gameplay设计有独到的见解，且熟悉
        topdown视角下的Gameplay构成要素，欢迎加入我们！我们特别重视在战斗策划中的
        系统理论修养，如果你还熟悉沙盒游戏，并且热爱二次元文化，将优先考虑。
      </div>
      <div class="topdown-gameplay-1 pingfangsc-regular-normal-white-16px">
        熟悉topdown视角的Gameplay构成要素<br />具备关于战斗手感与Gameplay设计的系统理论修养<br />熟悉沙盒游戏者优先<br />热爱二次元文化者加分
      </div>
    </div>
    <div class="overlap-group-32">
      <img
        class="rectangle-400-1"
        src="/assets/images/illustrations/rectangle-400@2x.png"
        alt="Rectangle 400"
      />
      <div class="text_label-9 pingfangsc-regular-normal-silver-chalice-16px">
        工作地点： 深圳南山前海<br />薪资待遇： 面议<br />简历投递： hr@autogame.ai
      </div>
      <img
        class="vector-23"
        src="/assets/images/icons/vector-8.png"
        alt="Vector"
      />
      <x-button :steam="xButtonProps.steam" buttonType="resume" class="resume-button" />
    </div>
  </div>
</template>

<script>
import XButton from "./XButton";
export default {
  name: "Frame5283",
  components: {
    XButton,
  },
  props: ["className", "xButtonProps"],
};
</script>

<style>
.frame-5-3-1 {
  background-image: url(/assets/images/illustrations/frame-531.png);
    background-position: 50% 50%;
  background-size: cover;
  box-shadow: -8px 12px 4px #00000040;
  height: 580px;
  overflow: hidden;
  position: relative;
  width: 498px;
  border-radius: 0;
}

.overlap-group-31 {
  height: 437px;
  left: 0;
  position: absolute;
  top: 0;
  width: 590px;
}

.vector-22 {
  height: 108px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 305px;
}


.rectangle-401-1 {
  background-color: var(--eerie-black-2);
  box-shadow: 0px 4px 4px #00000040;
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 590px;
}

.frame-478-3 {
  height: 371px;
  left: 102px;
  position: absolute;
  top: 0;
  width: 396px;
}

.frame-361-11 {
  background-image: url(/assets/images/logo/---1-3.svg);
  background-size: 100% 100%;
  height: 10px;
  left: 36px;
  position: absolute;
  top: 18px;
  width: 102px;
}

.text-18 {
  left: 36px;
  letter-spacing: -0.44px;
  line-height: normal;
  position: absolute;
  top: 69px;
}

.text-19 {
  left: 36px;
  letter-spacing: -0.4px;
  line-height: normal;
  position: absolute;
  top: 286px;
}

.ai-2 {
  left: 36px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: justify;
  top: 126px;
  width: 431px;
}

.topdown-gameplay-1 {
  left: 36px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: justify;
  top: 325px;
  width: 431px;
}

.overlap-group-32 {
  height: 115px;
  left: 0;
  position: absolute;
  top: 465px;
  width: 554px;
}

.rectangle-400-1 {
  height: 112px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 498px;
}

.text_label-9 {
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: justify;
  top: 26px;
  width: 518px;
}

.resume-button {
  position: absolute;
  right: 88;
  bottom: 32px;
  z-index: 10;
}

.vector-23 {
  height: 6px;
  left: 0;
  position: absolute;
  top: 0;
  width: 498px;
}

.frame-5-3-1.frame-5-3-2 .frame-361-11,
.frame-5-3-1.frame-5-3-3 .frame-361-11,
.frame-5-3-1.frame-5-3-5 .frame-361-11,
.frame-5-3-1.frame-5-3 .frame-361-11 {
  background-image: url(/assets/images/logo/---1-4.svg);
}
</style>

