<template>
  <div class="frame-371-8">
    <div class="frame-373-8"><div class="text_label-4 pingfangsc-normal-gallery-16px">联系我们</div></div>
    <div class="index-9 dinpro-bold-sonic-silver-16px">CONTACTS</div>
  </div>
</template>

<script>
export default {
  name: "Frame3713",
};
</script>

<style>
.frame-371-8,
.frame-371-9,
.frame_371_4,
.frame-371-10,
.frame-371-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
}

.frame-373-8,
.frame-373-9,
.frame_373_4,
.frame-373-10,
.frame-373-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.text_label-4,
.text_label-5,
.x4,
.text_label-6,
.text_label-7 {
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index-9,
.index-10,
.index_4,
.index-11,
.index-12 {
  letter-spacing: -0.48px;
  line-height: normal;
  position: relative;
  width: 100px;
}
</style>
